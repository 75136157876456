import SelectOptions from "../../ui/Select/Options";
import {FC, useRef, useState} from "react";
import {ITableServerHeadItem} from "../interfaces";
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import filterIcon from '../../../assets/icons/icon-filter-blue.svg'
import {useOnClickOutside} from "../../../helpers";
import Select from "../../ui/Select";
import Input from "../../../ui/Input";
import Btn from "../../../ui/Btn";
import {colors, removeSpacesInString} from "../../../utils";
import {onChangeGeneralSelect} from "../../ui/Select/functions";

const TableServerHeadItem:FC<ITableServerHeadItem> = ({
    title,
    index,
    name,
    fieldType,
    fieldTypes,
    activeFilter,
    setActiveFilter,
    onFilter,
    valueSearch,
    activeSort,
    setActiveSort,
    onChangeSorting,
    data,
    offFilter=false
}) => {
    const language = useAppSelector(currentUserLang);
    const [flagSort, setFlagSort] = useState(false);
    const sortDataset = {
        'ascending': trans('Ascending', language),
        'descending': trans('Descending', language),
        'default': trans('Default', language),
    }
    const onChangeSort = (type:'ascending' | 'descending' | 'default'='default') => {
        setFlagSort(false);
        const temp = activeSort;
        temp[name] = type;
        setActiveSort(temp);
        onFilter(activeFilter, temp);
    };

    const [flagSortByValue, setFlagSortByValue] = useState(false);

    const NumberSortByValue = ({
        activeFlag=false,
        setActiveFlag,
    }:{activeFlag?:boolean,setActiveFlag?:any}) => {
        const selectRef = useRef<HTMLDivElement>(null);
        useOnClickOutside(() => {
            setActiveFlag(false)
        }, [selectRef]);

        const [value, setValue] = useState<string>(activeFilter[name] ? activeFilter[name].value : '');
        const [condition, setCondition] = useState<string>(activeFilter[name] ? activeFilter[name].condition : '');
        const datasetConditions = {
            more: trans('More', language),
            less: trans('Less', language),
            equal: trans('Equal', language)
        }

        const onSubmit = ()=> {
            const temp = activeFilter;
            temp[name] = {
                condition: condition,
                value: removeSpacesInString(value.toString())
            }
            // if(fieldType==='percent') {
            //     temp[name]['value'] = (+value / 100).toString();
            // }
            setActiveFilter(temp);
            onFilter(temp, activeSort);

            setFlagSortByValue(false);
        }
        const onReset = ()=> {
            setCondition('');
            setValue('');
            const temp = activeFilter;
            delete temp[name];
            setActiveFilter(temp);
            onFilter(temp, activeSort);
            setFlagSortByValue(false);
        }

        return(
            <div className={`number-sort-modal ${activeFlag ? 'active' : ''}`} ref={selectRef}>
                <div className="number-sort-modal__choice">
                    <Select
                        color='dark'
                        selectActive={(datasetConditions as any)[condition] ? (datasetConditions as any)[condition] : trans('Condition', language)}
                        dataset={datasetConditions}
                        onChangeActive={(res:object)=>onChangeGeneralSelect(res, setCondition)}
                    />
                    <Input
                        name={'value'}
                        type={'float'}
                        value={value}
                        color={'dark'}
                        placeholder={trans('Value', language)}
                        onChange={(e) => setValue(e.value)}
                        isDisabled={!(datasetConditions as any)[condition]}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans('Apply', language)}
                        color={'blue'}
                        onClick={onSubmit}
                        isfullWidth={true}
                        disabled={!(value !== '' && +(removeSpacesInString(value)) >= 0)}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans('Reset', language)}
                        color={'light-blue'}
                        onClick={onReset}
                        isfullWidth={true}
                    />
                </div>
            </div>
        )
    }
    const StringSortByValue = ({
        activeFlag=false,
        setActiveFlag,
    }:{activeFlag?:boolean,setActiveFlag?:any}) => {
        const selectRef = useRef<HTMLDivElement>(null);
        useOnClickOutside(() => {
            setActiveFlag(false)
        }, [selectRef]);

        const [value, setValue] = useState<string>(activeFilter[name] ? activeFilter[name].value : '');
        const [condition, setCondition] = useState<string>(activeFilter[name] ? activeFilter[name].condition : '');
        const datasetConditions = {
            equal: trans('Equal', language),
            not_equal: trans('Not equal', language),
            contain: trans('Contain', language),
            not_contain: trans('Not contain', language),
        }

        const onSubmit = ()=> {
            // onChangeFilter(condition, value);
            const temp = activeFilter;
            temp[name] = {
                condition: condition,
                value: removeSpacesInString(value)
            }
            setActiveFilter(temp);
            onFilter(temp, activeSort);

            setFlagSortByValue(false);
        }
        const onReset = ()=> {
            setCondition('');
            setValue('');
            // setDataWrapper(data);
            const temp = activeFilter;
            delete temp[name];
            setActiveFilter(temp);
            onFilter(temp, activeSort);
            // onChangeFilter('default')
            setFlagSortByValue(false);
        }

        return(
            <div className={`number-sort-modal ${activeFlag ? 'active' : ''}`} ref={selectRef}>
                <div className="number-sort-modal__choice">
                    <Select
                        color='dark'
                        selectActive={(datasetConditions as any)[condition] ? (datasetConditions as any)[condition] : trans('Condition', language)}
                        dataset={datasetConditions}
                        onChangeActive={(res:object)=>onChangeGeneralSelect(res, setCondition)}
                    />
                    <Input
                        name={'value'}
                        type={'string'}
                        value={value}
                        color={'dark'}
                        placeholder={trans('Value', language)}
                        onChange={(e) => setValue(e.value)}
                        isDisabled={!(datasetConditions as any)[condition]}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans("Apply", language)}
                        color={'blue'}
                        onClick={onSubmit}
                        isfullWidth={true}
                        disabled={!(datasetConditions as any)[condition]}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans('Reset', language)}
                        color={'light-blue'}
                        onClick={onReset}
                        isfullWidth={true}
                    />
                </div>
            </div>
        )
    }

    return (
            data[0][index] !== '8|}$$~' && offFilter ?
            <th key={index}>
                <div className={`table-server__title ${index === 0 ? 'left':''}`}>
                    <span>{title ? title : name}</span>
                </div>
            </th>
            : (data[0][index] !== '8|}$$~' && fieldType === 'string') ?
            <th key={index}>
                <div className={`table-server__title ${index === 0 ? 'left':''}`}>
                    <span>{title ? title : name}</span>
                    {<button
                        className={`table-server__filter ${activeFilter[name] ? 'active':''}`}
                        onClick={() => setFlagSortByValue(true)}
                    >
                        <img src={filterIcon} alt=""/>
                    </button>}
                    <button
                        className={`table-server__filter ${activeSort && activeSort[name] && activeSort[name] !== 'default' ? 'active':''}`}
                        onClick={() => setFlagSort(true)}
                    >
                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.93421 3.19043L4.29132 0.833315L6.64844 3.19043" stroke="#007BFF"
                                  strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.29155 0.833301L4.29155 9.0832" stroke="#007BFF" strokeWidth="1.2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.2329 7.55945L10.8758 9.91656L8.51868 7.55945" stroke="#007BFF"
                                  strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.8755 1.66667L10.8755 9.91658" stroke="#007BFF" strokeWidth="1.2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <SelectOptions
                        onChange={onChangeSort}
                        dataWrapper={sortDataset}
                        activeFlag={flagSort}
                        setFlagSort={setFlagSort}
                        activeKey={activeSort && activeSort[name] && activeSort[name] !== 'default' ? activeSort[name] : 'default'}
                    />
                    <StringSortByValue
                        activeFlag={flagSortByValue}
                        setActiveFlag={setFlagSortByValue}
                    />
                </div>
            </th> : data[0][index] !== '8|}$$~' ?
            <th key={index}
                className={`${fieldType === 'date' ? 'date' : ''}`}>
                <div className={`table-server__title ${index === 0 ? 'left':''}`}>
                    <span>{title ? title : name}</span>
                    {fieldType !== 'date' && <button
                        className={`table-server__filter ${activeFilter[name] ? 'active':''}`}
                        onClick={() => setFlagSortByValue(true)}
                    >
                        <img src={filterIcon} alt=""/>
                    </button>}
                    <button
                        className={`table-server__filter ${activeSort && activeSort[name] && activeSort[name] !== 'default' ? 'active':''}`}
                        onClick={() => setFlagSort(true)}
                    >
                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.93421 3.19043L4.29132 0.833315L6.64844 3.19043" stroke="#007BFF"
                                  strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.29155 0.833301L4.29155 9.0832" stroke="#007BFF" strokeWidth="1.2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.2329 7.55945L10.8758 9.91656L8.51868 7.55945" stroke="#007BFF"
                                  strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.8755 1.66667L10.8755 9.91658" stroke="#007BFF" strokeWidth="1.2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <SelectOptions
                        onChange={onChangeSort}
                        dataWrapper={sortDataset}
                        activeFlag={flagSort}
                        setFlagSort={setFlagSort}
                        activeKey={activeSort && activeSort[name] && activeSort[name] !== 'default' ? activeSort[name] : 'default'}
                    />
                    <NumberSortByValue
                        activeFlag={flagSortByValue}
                        setActiveFlag={setFlagSortByValue}
                    />
                </div>
            </th> : <></>
    )
}

export default TableServerHeadItem;