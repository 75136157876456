import {FC, useState} from "react";
import {Input} from "../../../ui";
interface IInputSearch {
    name: string;
    sortBackend?: boolean;
    valueSearch?:string;
    onChangeValueSearch?: any;
    onChange:any;
}

const InputSearch:FC<IInputSearch> = ({name,sortBackend=false,valueSearch='',onChangeValueSearch, onChange}) => {

    const [valueSearchCustom, setValueSearchCustom] = useState('');
    const onChangeValueSearchCustom = (value:string) => {
        if(value === '') {
            onChange('');
            setValueSearchCustom('')
            return;
        }
        onChange(value);
        setValueSearchCustom(value);
    }

    return(
        <Input
            className={'custom-input--search'}
            type={'text'}
            name={'field-search'}
            placeholder={name}
            isSearch
            value={sortBackend ? valueSearch : valueSearchCustom}
            onChange={sortBackend ? onChangeValueSearch : onChangeValueSearchCustom}
            isDirectCbInput={true}
        />
    )
}

export default InputSearch;