import {FC} from "react";
import {ITablePaginationHeadStretchItem} from "../interfaces";

const TablePaginationHeadStretchItem:FC<ITablePaginationHeadStretchItem> = ({
    title,
    index,
    data,
    colSpan=1
}) => {
    return (
        data && data[0] && data[0][index] !== '8|}$$~' && title ?
        <th colSpan={colSpan?colSpan:1} className='table-pagination__stretch-cell'>
            <div className='table-pagination__cell'>
                <div className={`table-pagination__title ${index === 0 ? 'left':''}`}>
                    {title ? title : 'undefined'}
                </div>
            </div>
        </th> : data && data[0] && data[0][index] !== '8|}$$~' ?
        <th colSpan={1} className='table-pagination__stretch-cell'></th> : <></>
    )
}

export default TablePaginationHeadStretchItem;