import {DefaultObject} from "../../../interfaces/common.d";
import {formattingValues} from "../../../utils";
import Alt from "../../Alt";


const DashboardOutputValue = ({
    columnPreferences,
    itemIndex,
    value,
    rowIndex,
    itemCountPercent,
    currentPage=1,
    limitChannelCustom=10,
    currency='RUB',
    language='ru'
}:{
    columnPreferences:DefaultObject<any>,
    itemIndex:number,
    value:string,
    rowIndex:number,
    itemCountPercent:Array<any>,
    currentPage:number,
    limitChannelCustom:number,
    currency:'USD'|'EUR'|'RUB',
    language?:string
}) => (
    <div className="table-pagination-row__block">
        <div className="table-pagination-row__text">
            {(columnPreferences[itemIndex].type === 'percent' || columnPreferences[itemIndex].agg_function === 'avg') && itemCountPercent && itemCountPercent[rowIndex+((currentPage-1)*limitChannelCustom)] && columnPreferences[itemIndex].agg_function !== 'calc' ?
                formattingValues((+value / +itemCountPercent[rowIndex + ((currentPage-1)*limitChannelCustom)]), columnPreferences[itemIndex].type, currency, false, language) :
                formattingValues(value, columnPreferences[itemIndex].type, currency, false, language)}
        </div>
        { columnPreferences[itemIndex].type === 'string' && value.toString().length > 18 &&
            <Alt text={value} active={false}/>
        }
    </div>
)

export default DashboardOutputValue;