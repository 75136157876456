import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";

import {getEvents} from "../../../../../actions/events";

import EventsCreate from "../../../../../components/EventsCreate";
import {trans} from "../../../../../_locales";
import {sendSdk} from "../../../../../utils";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";
import {projectGeneral} from "../../../../../redux/slices/project";

const EventsEdit = () => {
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectId, eventsId } = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('events') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;

  useEffect(()=> {
    if(projectId && reduxUserId && eventsId) {
        sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'events_edit', 'event_id': eventsId}})
    }
  }, [projectId, reduxUserId, eventsId])

  useEffect(() => {
    dispatch(setBreadcrumbs([
        {
          href: '/projects/',
          title: 'Проект',
        },
        {
          href: `/projects/${projectId}/events/`,
          title: trans('Event targets', language)
        },
        {
          href: '#',
          title: trans('Edit', language),
        }
    ]))
    dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'events'
    })))
    dispatch(clearInstructions());
  }, [dispatch, projectId, reduxUser.roles, permissions]);

  const [dataEdit, setDataEdit] = useState();

  useEffect(() => {
    dispatch(getEvents({projectId, calcAttr: false})).then((r)=> {
        if(r.payload) {
            const current = r.payload.filter((item:any)=> item.id === Number(eventsId))[0];
            if(permissions && (!isEdit && current.author_id !== reduxUserId)) {
                navigate(`/projects/${projectId}/events/`)
            }
          setDataEdit(current);
        }
    });
  }, [dispatch])

  return (
        <div>
          <EventsCreate edit={true} dataEdit={dataEdit}/>
        </div>
  );
};


export default EventsEdit;