import React, {ChangeEvent, FC, useEffect, useRef, useState} from "react";

import cls from './style.module.scss';
import {updateProjectEvents} from "../../actions/project";
import {useNavigate, useParams} from "react-router-dom";
import {Toggle} from "../../ui";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUserLang, userId} from "../../redux/slices/user";
import {eventsData} from "../../redux/slices/events";
import {updateEvent} from "../../actions/events";
import Tooltip from "../Tooltip";
import DocsCounter from "../Modals/DocsCounter";
import Btn from "../../ui/Btn";
import {trans} from "../../_locales";
import {getNumberWithSpaces, removeSpacesInString, sendSdk} from "../../utils";
import {useOnClickOutside} from "../../helpers";
import CodeFormatter from "../CodeFormatter";

interface EventsCreateProps {
  className?: string;
  edit?: boolean;
  dataEdit?: IData
}

interface IData {
  id?: number;
  event_target_name?: string;
  event_target_price?: number
  calculate_attribution_models?: boolean
  event_type?: string
  event_category?: string
  event_name: string
  event_value?: number
  count?:number;
  created_at?:string;
}

const EventsCreate: FC<EventsCreateProps> = ({
  className,
  edit ,
  dataEdit
}) => {
  const projectId = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const current = useAppSelector(eventsData);
  const language = useAppSelector(currentUserLang);
  const reduxUserId = useAppSelector(userId);
  const { eventsId } = useParams();

  const [targetName, setTargetName] = useState('');
  const [targetPrice, setTargetPrice] = useState(0);
  const [calculateModels, setCalculateModels] = useState(false);
  const [eventType, setEventType] = useState<string>('');
  const [eventCategory, setEventCategory] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventValue, setEventValue] = useState<number | string>(0);
  const [data, setData] = useState<IData>({
    event_type : eventType,
    event_category : eventCategory,
    event_name : eventName
  });
  const [sdk, setSdk] = useState<IData>({
    event_type : eventType,
    event_category : eventCategory,
    event_name : eventName
  });
  useEffect(()=>{
    const item = current.filter((item:any)=> item.id === Number(eventsId))[0];
    if(item) {
      setTargetName(item.event_target_name ? item.event_target_name : '');
      setTargetPrice(item.event_target_price ? item.event_target_price : -1);
      setCalculateModels(item.calculate_attribution_models ? item.calculate_attribution_models : false);
      setEventType(item ? item.event_type : '');
      setEventCategory(item ? item.event_category : '');
      setEventName(item ? item.event_name : '');
      setEventValue(item.event_value ? item.event_value : -1);
      setData(item);
      const temp:IData = {
        event_type: '',
        event_category: '',
        event_name: '',
      };
      // temp.event_target_name = item.event_target_name;
      temp.event_type = item.event_type;
      temp.event_category = item.event_category;
      temp.event_name = item.event_name;
      if(item.event_value) temp.event_value = item.event_value;
      // if(item.event_target_price) temp.event_target_price = item.event_target_price;
      setSdk(temp);
    }
  },[current])

  const [step, setStep] = useState(1);
  const [exitCounter, setExitCounter] = useState<boolean>(false);
  const changeCalculateModels = () => {
    const result = !calculateModels;
    setCalculateModels(result);
    setData({...data, calculate_attribution_models: result});
    if(targetName && eventName) setExitCounter(true);
  }

  const changeTargetName = (value:string)=> {
    setTargetName(value);
    setData( {...data, event_target_name: value});
    // setSdk( {...sdk, event_target_name: value});
    value && eventName ? setExitCounter(true) : setExitCounter(false);
  }
  const changeTargetPrice = (value:string) => {
    if(!isNaN(Number(value))) {
      setTargetPrice(Number(value));
      setData({...data, event_target_price: Number(value)});
      // setSdk({...sdk, event_target_price: Number(value)});
      if(targetName && eventName) setExitCounter(true);
      return;
    }
    setTargetPrice(0);
  }
  const changeEventValue = (value:string) => {
    if(!isNaN(Number(removeSpacesInString(value)))) {
      const result = getNumberWithSpaces(value);
      setEventValue(result);
      setData( {...data, event_value: Number(removeSpacesInString(result))});
      setSdk( {...sdk, event_value: Number(removeSpacesInString(result))});
      if(targetName && eventName) setExitCounter(true);
      return;
    }
    setEventValue(0);
  }

  const changeEventCategory = (value:string)=> {
    setEventCategory(value);
    setData( {...data, event_category: value});
    setSdk( {...sdk, event_category: value});
    if(targetName && eventName) {
      setExitCounter(true);
    } else setExitCounter(false);
  }
  const changeEventName = (value:string)=> {
    setEventName(value);
    setData( {...data, event_name: value});
    setSdk( {...sdk, event_name: value});
    if(targetName && value) {
      setExitCounter(true);
    } else setExitCounter(false);
  }
  const changeEventType = (value:string)=> {
    setEventType(value);
    setData( {...data, event_type: value});
    setSdk( {...sdk, event_type: value});
    if(targetName && eventName) {
      setExitCounter(true);
    } else setExitCounter(false);
  }

  const onSubmitFirst = () => {
    if (!(targetName)) {
      return;
    }
    setExitCounter(false)
    if(calculateModels) {
      setData( {...data, calculate_attribution_models: calculateModels});
    }
    onSubmitSecond();
    // setStep(2);
  }
  const [sdkFuncText, setSdkFuncText] = useState<string>(
    `<script>
_targetadsTag('event', ${JSON.stringify(sdk)})
</script>`);
  const onSubmitSecond = () => {
    if (!(eventName)) {
      return;
    }
    if(edit) {
      dispatch(updateEvent({data: data, projectId: Number(projectId.projectId), eventsId: Number(eventsId)})).then((r)=> {
        if(r && r.meta.requestStatus !== 'rejected') {
          setExitCounter(false);
          setStep(2);
          sendSdk('event', {
            'event_type': 'edit',
            'event_category': 'events',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
              'project_id': projectId.projectId,
              'user_id': reduxUserId,
              'event_id': eventsId
            }
          });
        }
      })
    } else {
      dispatch(updateProjectEvents({data: data, projectId: Number(projectId.projectId)})).then((r)=>{
        if(r && r.meta.requestStatus !== 'rejected') {
          setExitCounter(false);
          setStep(2);
          sendSdk('event', {
            'event_type': 'create',
            'event_category': 'events',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
              'project_id': projectId.projectId,
              'user_id': reduxUserId,
              'event_id': eventsId
            }
          });
        }
      })
    }
  }

  const onSubmit = (type:string='all') => {
    setExitCounter(false);

    setStep(1);
    setTargetName('');
    setTargetPrice(-1);
    setCalculateModels(false);
    setEventType('');
    setEventCategory('');
    setEventName('');
    setEventValue(-1);
    setData({
      event_type: eventType,
      event_category: eventCategory,
      event_name: eventName
    });
    setSdk({
      event_type: eventType,
      event_category: eventCategory,
      event_name: eventName
    });
    setCheckCopy(false);
    if(type === 'all')
      navigate(`/projects/${projectId.projectId}/events/`);
    if(type === 'create')
      navigate(`/projects/${projectId.projectId}/events/create/`);
  }

  let progressbar;
  if(step === 1) {
    progressbar = cls.stepFirst;
  }
  if(step === 2) {
    progressbar = cls.stepSecond;
  }
  if(step === 3) {
    progressbar = cls.stepThird;
  }

  const [styleUrl, setStyleUrl] = useState(false)
  const [checkCopy, setCheckCopy] = useState<boolean>(false)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(sdkFuncText).then(r => document.execCommand('Copy')).then(()=>{
      setCheckCopy(true);
      setTimeout(()=>setCheckCopy(false), 3000);
    });
  }

  const [showDocs, setShowDocs] = useState(false);
  const [showCopyBlock, setShowCopyBlock] = useState(!!edit);
  const onHandlerModal = ()=> {
    setShowDocs(true);
  }

  const refDocs = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(() => setShowDocs(false), [refDocs]);
  useEffect(() => {
    if(exitCounter) {
      setShowCopyBlock(false);
    }
  }, [exitCounter]);
  useEffect(() => {
    if(sdk) {
      if(!sdk.event_category) delete sdk.event_category;
      if(!sdk.event_type) delete sdk.event_type;
      if(!sdk.event_value) delete sdk.event_value;
      setSdkFuncText(`<script>
    _targetadsTag('event', ${JSON.stringify(sdk)})
</script>`);
    }
  }, [sdk]);

  return (
    <div className={cls.eventCreate}>
      <div>
        <div className={cls.progress}>
          <div className={cls.progress__title}>{edit ? trans('Event editing', language) : trans('Event creation', language)} {step}/2</div>
          <div className={cls.progress__line}><span className={progressbar}></span></div>
        </div>
        {step === 1 ?
          <div className={cls.container}>
            <div className={cls.form}>
              <div className={cls.wrapper}>
                <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Event target name', language)} * <Tooltip
                      text={trans("The name will be displayed only in the interface. We advise you to give simple names, for example, 'Application for consultation'", language)}/></span>
                  <input className={cls.input} type='text' value={targetName}
                         onChange={(e) => changeTargetName(e.target.value)}/>
                </div>
                <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Target cost', language)} <Tooltip
                      text={trans('Add the target cost of your event (KPI) and in some reports we will show you ineffective campaigns.', language)}/></span>
                  <input className={styleUrl ? cls.input_error : cls.input}
                         type='text'
                         value={targetPrice !== -1 ? targetPrice : ''}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           changeTargetPrice(e.target.value);
                         }}
                  />
                </div>

                <div className={cls.entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='switcher'>{trans('Calculate attribution models', language)} <Tooltip
                        text={trans('Whether to calculate attribution models for this event.', language)}/></label>
                    <Toggle
                        className={cls.switch}
                        name={'status'}
                        isSlider={true}
                        isChecked={calculateModels}
                        onChange={changeCalculateModels}/>
                  </div>
                </div>

                <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Event type', language)}</span>
                  <input
                      className={cls.input}
                      type='text'
                      value={eventType}
                      onChange={(e) => changeEventType(e.target.value)}
                      placeholder={trans('Input event type', language)}/>
                </div>
                <div className={cls.input_section}>
                  <span>{trans('Event category', language)}</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventCategory}
                      onChange={(e) => changeEventCategory(e.target.value)}
                      placeholder={trans('Input event category', language)}/>
                </div>
                <div className={cls.input_section}>
                  <span>{trans('Event name', language)} *</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventName}
                      onChange={(e) => changeEventName(e.target.value)}
                      placeholder={trans('Input event name', language)}/>
                </div>
                <div className={cls.input_section}>
                  <span>{trans('Event value', language)}</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventValue !== -1 ? eventValue : ''}
                      onChange={(e) => changeEventValue(e.target.value)}
                      placeholder={trans('Input event value', language)}/>
                </div>
              </div>
              <div className={cls.btns}>
                <button className={(exitCounter) ? cls.submit_btn : cls.submit_btn_disactive} onClick={onSubmitFirst}>
                  {edit ? trans('Update event', language) : trans('Create event', language)}
                </button>
              </div>
            </div>
            {showCopyBlock && <div className={cls.section_copy_block}>
              <div className={cls.copy_section}>
                {/*<textarea*/}
                {/*  className={cls.sdk_code}*/}
                {/*  value={sdkFuncText}*/}
                {/*  disabled={true}*/}
                {/*/>*/}
                <CodeFormatter className={cls.sdk_code} code={sdkFuncText} />
                <button
                  className={checkCopy ? cls.copied : cls.copy}
                  type='submit'
                  onClick={copyToClipboard}
                >
                  {checkCopy ? trans('Copied', language) : trans('Copy', language)}
                </button>
              </div>

              <p className={cls.text}>{trans('Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.', language)}</p>

              <div className={cls.btns}>
                <Btn
                  text={trans('Counter documentation', language)}
                  color='light-blue'
                  onClick={onHandlerModal}
                />
              </div>
            </div>}
          </div>
        :
        ''
      }
      {step === 2 ?
        <div className={cls.form_finish}>
          <div className={''}>
            <div className={cls.copy_section}>
              <textarea
                className={cls.sdk_code}
                value={sdkFuncText}
                disabled={true}
              />
              <button
                className={checkCopy ? cls.copied : cls.copy}
                type='submit'
                onClick={copyToClipboard}
              >
                {checkCopy ? trans('Copied', language) : trans('Copy', language)}
              </button>
            </div>

            <p className={cls.text}>{trans('Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.', language)}</p>
          </div>
          <div className={cls.btns}>
            <button className={ cls.submit_btn } onClick={()=>onSubmit()}>
              {trans('All events', language)}
            </button>
            <Btn
              text={trans('Counter documentation', language)}
              color='light-blue'
              onClick={onHandlerModal}
            />
          </div>
        </div>
        :
        ''
      }
      </div>
      {showDocs && <div className={cls.modal} ref={refDocs}>
        <DocsCounter title={trans('Counter documentation', language)} onClose={()=>setShowDocs(false)}/>
      </div>}
    </div>
  );
};

export default EventsCreate;