import {colors, filterAndSortDataByMaxValues, formatDate, formattingValues, isNumber} from "../../../../utils";
import {getDateAgo, getMonday} from "../../../../components/ui/TimeFilter/functions";

export const tooltipLinear = (type?:string, currency?: 'USD' | 'EUR' | 'RUB', otherType?:string, otherIndex?:number):any => {
    const temp:any = {
        callbacks: {
            labelColor: function (context: any) {
                return {
                    borderColor: 'transparent',
                    backgroundColor: context.dataset.backgroundColor,
                    borderWidth: 0,
                };
            },
        },
        intersect: false,
        mode: "index",
        backgroundColor: '#F9FAFF',
        cornerRadius: 10,
        borderColor: 'rgba(67, 88, 117, 0.08)',
        borderWidth: 1,
        titleColor: '#8E9EB5',
        bodyColor: '#435875',
        padding: {
            top: 12,
            right: 22,
            bottom: 12,
            left: 22,
        },
        position: 'bottom'
    }
    if(type) {
        temp.callbacks.label = function(context:any) {
            const label = context.dataset.label || '';
            const value = context.raw ? context.raw : 0;
            if(label) {
                if(context.datasetIndex === otherIndex && otherType) {
                    if(otherType === 'percent') {
                        return `${label} - ${formattingValues(value, 'float')}%`;
                    }
                    return `${label} - ${formattingValues(value, otherType, currency)}`;
                }
                if(type === 'percent') {
                    return `${label} - ${formattingValues(value, 'float')}%`;
                }
                return `${label} - ${formattingValues(value, type, currency)}`;
            }
            return '';
        }
    }
    return temp;
}
export const tooltipBar = (type?:string, currency?: 'USD' | 'EUR' | 'RUB', otherType?:string, otherIndex?:number):any => {
    const temp: any = {
        callbacks: {
            labelColor: function (context: any) {
                return {
                    borderColor: 'transparent',
                    backgroundColor: context.dataset.backgroundColor,
                    borderWidth: 0,
                };
            },
        },
        intersect: false,
        mode: "index",
        backgroundColor: '#F9FAFF',
        cornerRadius: 10,
        borderColor: 'rgba(67, 88, 117, 0.08)',
        borderWidth: 1,
        titleColor: '#8E9EB5',
        bodyColor: '#435875',
        padding: {
            top: 12,
            right: 22,
            bottom: 12,
            left: 22,
        },
        position: 'bottom'
    }
    if(type) {
        temp.callbacks.label = function(context:any) {
            const label = context.dataset.label || '';
            const value = context.raw ? context.raw : 0;
            if(label) {
                if(context.datasetIndex === otherIndex && otherType) {
                    if(otherType === 'percent') {
                        return `${label} - ${formattingValues(value, 'float')}%`;
                    }
                    return `${label} - ${formattingValues(value, otherType, currency)}`;
                }
                if(type === 'percent') {
                    return `${label} - ${formattingValues(value, 'float')}%`;
                }
                return `${label} - ${formattingValues(value, type, currency)}`;
            }
            return '';
        }
    }
    return temp;
}

export const onHandlerChart = (response:any, setShowLoader:any,setData:any, setOptions:any, currency?: 'USD' | 'EUR' | 'RUB', settings?:any, labelIndex=0, dataIndexes=[1], positionLabels:'top'|'right'|'left'|'bottom'='top', containerRef?:any) => {
    setShowLoader(false);
    if (response && response.data) {
        if(settings && settings.pie) {
            const tempNames:Array<any> = [];
            let tempValues:Array<any> = [];
            const tempData = response.data.dataset
            tempData.slice(0,10).map((item:any)=> {
                tempNames.push(item[0]);
                tempValues.push(item[1]);
            })
            if(tempData.length > 10) {
                const tempOther = tempData.slice(10).reduce((partialSum: number, a: any) => partialSum + +a[1], 0);
                tempNames.push(`other`);
                tempValues.push(tempOther);
            }
            const allSum = tempData.reduce((partialSum: number, a: any) => partialSum + +a[1], 0);
            if(allSum === 0)
                tempValues = [];
            else tempValues = tempValues.map((item:any)=> ((item/allSum)*100).toFixed(2));

            const temp:any = {
                labels: tempNames,
                datasets: [
                    {
                        label: '# of Percent',
                        data: tempValues,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            }
            setData(temp);
            setShowLoader(false);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: {
                        callbacks: {
                            title: function () {
                                return '';
                            },
                            label: function (context: any) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label = `${context.label} - ${formattingValues(context.parsed, 'float')}%`;
                                }
                                return label;
                            },
                            labelColor: function (context: any) {
                                return {
                                    borderColor: 'transparent',
                                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                                    borderWidth: 0,
                                    borderRadius: 5,
                                };
                            },
                        },
                        backgroundColor: '#F9FAFF',
                        cornerRadius: 10,
                        borderColor: 'rgba(67, 88, 117, 0.08)',
                        borderWidth: 1,
                        titleColor: '#8E9EB5',
                        bodyColor: '#435875',
                        padding: {
                            top: 12,
                            right: 22,
                            bottom: 12,
                            left: 22,
                        }
                    },
                },
            })
        } else if(settings && settings.bar_linear) {
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const labels: any = [];
            const linear: any = [];
            const bar: any = [];
            const barIndex = 1;
            const lineIndex = 2;
            const typeBar = types[barIndex];
            const typeLine = types[lineIndex];
            const onHandleValue = (value:number, type?:string) => {
                const temp = isNumber(value) ? +value : 0;
                if(type === 'percent') {
                    return temp * 100;
                }
                return temp;
            }
            response.data.dataset.map((list: any) => {
                labels.push(list[0]);
                bar.push(onHandleValue(list[barIndex], typeBar));
                linear.push(onHandleValue(list[lineIndex], typeLine));
            })
            const temp = {
                labels: labels,
                datasets: [
                    {
                        type: 'line' as const,
                        label: response.data.column[lineIndex].title,
                        borderColor: `${colors[0]}`,
                        backgroundColor: colors[0],
                        borderWidth: 2,
                        fill: false,
                        data: linear,
                        yAxisID: 'y1',
                    },
                    {
                        type: 'bar' as const,
                        label: response.data.column[barIndex].title,
                        backgroundColor: colors[1],
                        data: bar,
                        yAxisID: 'y'
                    },
                ],
            }
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}`,
                    },
                    title: {
                        display: true,
                        text: response.item_title
                    },
                    tooltip: tooltipLinear(typeLine, currency, typeBar, 1)
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                }
            })
            setShowLoader(false);
        } else if(settings && settings.bar_stack_linear) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {};
            const linear: any = {};
            const linearIndex = 3;
            const linearType = types[linearIndex];

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if(!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex && index !== linearIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;

                    const impression = list[linearIndex] ? list[linearIndex] : 0;
                    const click = list[2] ? list[2] : 0;
                    if(linear[list[labelIndex]]) {
                        linear[list[labelIndex]].clicks += click;
                        linear[list[labelIndex]].impressions += impression;
                    } else linear[list[labelIndex]] = {
                        clicks: click,
                        impressions: impression
                    }
                // }
            })
            const linearDataset:number[] = [];
            Object.keys(linear).map((key)=> {
                const a = linear[key].clicks;
                const b = linear[key].impressions;
                if(b > 0) {
                    const value = (a / b) * 100;
                    linearDataset.push(value<100?value:100);
                } else {
                    linearDataset.push(0);
                }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: [
                    {
                        type: 'line' as const,
                        label: response.data.column[3].title,
                        borderColor: `${colors[0]}`,
                        backgroundColor: colors[0],
                        borderWidth: 2,
                        fill: false,
                        data: linearDataset,
                        yAxisID: 'y1',
                    },
                ]
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })

                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: colors[index+1],
                        stack: 'Stack 0',
                    })
                }
            })
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipBar(typeValue,currency, linearType, 0)
                },
                interaction: {
                    mode: 'index' as const,
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            })
        } else if(settings && settings.bar_stack) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {}

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if(!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;
                // }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: []
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })

                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: colors[index],
                        stack: 'Stack 0',
                    })
                }
            })
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipBar(typeValue,currency)
                },
                interaction: {
                    mode: 'index' as const,
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
            })
        } else if(settings && settings.linear_multi) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {}

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if (!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;
                // }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: []
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const fillValue = !!settings.fill;
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })
                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: settings.fill ? `${colors[index]}65` : colors[index],
                        borderColor: colors[index],
                        borderWidth: 2,
                        fill: fillValue
                    })
                }
            })
            setData(temp);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(typeValue,currency),
                }
            }
            setOptions(tempOptions)
        } else {
            const labels: any = [];
            const data: any = [];
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            let typeValue = '';
            response.data.dataset.map((list: any) => {
                list.map((item: any, index: number) => {
                    if (index === labelIndex) {
                        labels.push(list[labelIndex])
                    }
                    if (dataIndexes.includes(index, 0)) {
                        if (types[index])
                            typeValue = types[index];
                        const tempIndex = dataIndexes.indexOf(index);
                        let value = list[index] ? list[index] : 0;
                        types[index] === 'percent' && (value = +value * 100);
                        data[tempIndex] ? data[tempIndex].push(value) : data.push([value]);
                    }
                })
            })
            const temp: any = {
                labels: labels,
                datasets: []
            }
            dataIndexes.map((item: any, index: number) => {
                temp.datasets.push({
                    label: response.data.column[item].title,
                    data: data[index],
                    backgroundColor: `${colors[index]}`,
                })
                if (settings && settings.linear) {
                    temp.datasets[index].borderColor = `${colors[index]}`;
                    temp.datasets[index].borderWidth = 2;
                }
                if (settings && settings.fill) {
                    temp.datasets[index].fill = true;
                    temp.datasets[index].backgroundColor = `${colors[index]}65`;
                }
            })
            setData(temp);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(typeValue,currency),
                }
            }
            if (settings && settings.linear) tempOptions.plugins.tooltip = tooltipLinear(typeValue,currency);
            if (settings && settings.bar) tempOptions.plugins.tooltip = tooltipBar(typeValue, currency);
            setOptions(tempOptions)
        }
    }
}

export const onLoadingSelectDate = (
    response:any,
    setPeriodStart:any,
    setPeriodEnd:any,
    setValue:any,
    theDate?:Date
) => {
    const date = theDate ? theDate : new Date();
    const data = {
        start: formatDate(getDateAgo(date, 1)),
        end: formatDate(getDateAgo(date, 1))
    }
    switch (response) {
        case 'month': {
            let count_month_ago = 0;
            if (date.getDate() === 1)
                count_month_ago = 1;
            data.start = formatDate(new Date(date.getFullYear(), date.getMonth() - count_month_ago));
            break;
        }
        case 'previous_month': {
            const year = date.getFullYear(),
                month = date.getMonth();
            let count_month_ago = 1;
            if (date.getDate() === 1)
                count_month_ago = 2;
            data.start = formatDate(new Date(year, month - count_month_ago));
            data.end = formatDate(new Date(year, month - count_month_ago, 33 - new Date(year, month - count_month_ago, 33).getDate()));
            break;
        }
        case 'week': {
            data.start = date.getDay() === 1 ?
                formatDate(getDateAgo(getMonday(date), 7)) :
                formatDate(getMonday(date));
            break;
        }
        case 'previous_week': {
            let start_day_ago = 7,
                end_day_ago = 1;
            if (date.getDay() === 1) {
                start_day_ago = 14;
                end_day_ago = 8;
            }
            data.start = formatDate(getDateAgo(getMonday(date), start_day_ago));
            data.end = formatDate(getDateAgo(getMonday(date), end_day_ago));
            break;
        }
        default:
            return;
    }
    setPeriodStart(data.start);
    setPeriodEnd(data.end);
    setValue(`${data.start} - ${data.end}`);
}
