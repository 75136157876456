import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  getEvents
} from '../../actions/events'

interface ICurrentEvents {
  event_target_name?: string,
  event_target_price?: number,
  calculate_attribution_models?: boolean,
  event_type: string,
  event_category: string,
  event_name: string,
  event_value?: number
}
interface ProjectState {
  data: ICurrentEvents[] | []
  loaded: boolean
  isLoading: boolean
  error: string | null
}

const initialState: ProjectState = {
  data: [],
  loaded: false,
  isLoading: false,
  error: null
}

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.data = action.payload;
    },
    clearEvents: (state) => {
      state.data = [];
    },
    setEventsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEvents.fulfilled,
      (state, action: PayloadAction<ICurrentEvents[]>) => {
      state.data = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getEvents.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getEvents.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      if (action.payload === 'Somehow settings were not found')
        state.data = [];
      state.error = action.payload
    });
  }
})

export const { setEvents,
  removeError,
  clearEvents,
  setEventsLoaded
} = eventsSlice.actions

export const eventsData = createSelector(
  (state: RootState) => state.events,
  (events) => events.data
)

export default eventsSlice.reducer

