import {FC, useEffect, useRef, useState} from "react";
import './style.scss';
import Loader from "../Loader";
import {formattingValues} from '../../utils';
import {useAppSelector} from "../../redux/hooks";
import {projectGeneral} from "../../redux/slices/project";
import Empty from "../Empty";
import {getFuncIndexes} from "./functions";
import {ITableServer} from "./interfaces";
import Pagination from "../ui/Pagination";
import Tooltip from "../Tooltip";
import Select from "../ui/Select";
import downloadIcon from '../../assets/icons/download.svg';
import {trans} from "../../_locales";
import {currentUserLang} from "../../redux/slices/user";
import TableServerHeadItem from "./Head";
import Btn from "../../ui/Btn";
import {useOnClickOutside} from "../../helpers";
import {getCountPage} from "../TablePagination/functions";
import Alt from "../Alt";

const TableServer: FC<ITableServer> = ({
    data = [],
    title ,
    subDescription,
    columnPreferences=[],
    fieldTypes,
    valueSearch='',
    pageCount,
    loader= true,
    dateGrouping,
    name,
    tooltip,
    onRepeat = () => null,
    aggFunctions=[],
    aggColIndexes=[],
    aggChoice=[],
    offFilter=false,
    activeSorting={},
    activeFiltering={},
    limit,
    offset=0,
    onDownload,
    noActual=false,
    offAgg=false,
    defaultRowsOnPage=10
}) => {
    const generalProjectInfo = useAppSelector(projectGeneral);
    const language = useAppSelector(currentUserLang);
    const currency= generalProjectInfo && generalProjectInfo.currency || 'USD';

    const [dataWrapper, setDataWrapper] = useState<Array<any>>([]);
    const [dataReady, setDataReady] = useState(false);

    const [aggColIndexesDefault, setAggColIndexes] = useState<Array<any>>(aggColIndexes.length > 0 ? aggColIndexes : [])
    const [selectActive, setSelectActive] = useState<string>(trans('Select aggregation', language));
    const [selectDataset, setSelectDataset] = useState<any>({});
    const [aggUpdate, setAggUpdate] = useState<boolean>(true);
    const arrStringFunc = getFuncIndexes(aggFunctions, 'string');

    const countWrapperIndexes = () => {
        const temp:Array<number> = [];
        columnPreferences.map((item:any, index:number)=> {
            if(item.type !== 'skip')
                temp.push(index);
        })
        return temp;
    }
    const [wrapperIndexes, setWrapperIndexes] = useState<Array<number>>([])

    useEffect(()=> {
        setWrapperIndexes(countWrapperIndexes())
    }, [columnPreferences])

    useEffect(()=> {
        if(aggColIndexes.length > 0) {
            if(arrStringFunc.length > 0)
                setAggColIndexes(aggColIndexesDefault.concat(arrStringFunc).sort((a, b)=> a-b));
            else
                setAggColIndexes(aggColIndexes);
        }
    }, [aggColIndexes])
    const onChangeSelect = (response:any) => {
        try {
            setAggUpdate(true);
            const tempIndexes:any = []
            const tempTitles:any = []
            Object.keys(response).map((index:any)=> {
                if(response[index].checked) {
                    tempIndexes.push(+index);
                    tempTitles.push(response[index].name)
                }
            })
            if(tempTitles.length === 0)
                setSelectActive(trans('Select aggregation', language));
            else
                setSelectActive(tempTitles.join(', '));
            setAggColIndexes([...tempIndexes].sort((a, b)=> a-b));
            setUpdatedSetting(true);
        } catch (e) {
            console.log('Error - ', e);
        }
    }
    useEffect(()=> {
        if(aggChoice.length > 0 && columnPreferences.length > 0) {
            try {
                setAggUpdate(true);
                const dataset:any = {};
                aggChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    dataset[index] = columnPreferences[index] ? columnPreferences[index].title : item;
                })
                setSelectDataset(dataset);
                if(Object.values(dataset).length > 0 && aggColIndexesDefault.length > 0) {
                    const temp:Array<string> = [];
                    aggColIndexesDefault.map((index)=> {
                        temp.push(columnPreferences[+index].title)
                    })
                    setSelectActive(temp.join(', '))
                } else {
                    setSelectActive(trans('Select aggregation', language));
                }
            } catch (e) {
                console.log('Error - ', e);
            }
            setAggUpdate(false);
        }
    }, [aggChoice])

    useEffect(()=> {
        if(data.length>0 && dateGrouping && typeof fieldTypes === 'object' && fieldTypes.length > 0 && columnPreferences.length > 0) {
            try {
                setDataWrapper(data);
                if(Object.values(selectDataset).length > 0) {
                    const temp:Array<string> = [];
                    aggColIndexesDefault.map((index)=> {
                        temp.push(columnPreferences[+index].title)
                    })
                    if(temp.length === 0)
                        setSelectActive(trans('Select aggregation', language));
                    else
                        setSelectActive(temp.join(', '));
                }
                setDataReady(true);
                setAggUpdate(false);
                return;
            } catch (e) {
                console.log('Error - ', e);
                setDataWrapper([]);
                setAggUpdate(false);
                return;
            }
        }
        setAggUpdate(false);
    },[data, dateGrouping, aggColIndexesDefault])


    const [limitChannelCustom, setLimitChannelCustom] = useState(defaultRowsOnPage);

    const per_page_max = pageCount ? pageCount : dataWrapper ? getCountPage(dataWrapper.length, limitChannelCustom) : 1;

    const [currentPage, setCurrentPage] = useState(1);
    const pageItems = [];
    for(let i = 1; i <= per_page_max; i++) {
        pageItems.push(i);
    }

    const [activeSort, setActiveSort] = useState<any>(activeSorting);
    const [activeFilter, setActiveFilter] = useState<any>(activeFiltering);
    const onChangeSort = (activeIndex:number, type:'ascending' | 'descending' | 'default'='default')=> {
        setActiveSort(type);
    }

    const onChangeFilter = (filter:any={}, sort:any={}) => {
        setAggUpdate(true);
        setActiveSort(sort);
        setActiveFilter(filter)
        setAggUpdate(false);
        setUpdatedSetting(true);
    }

    const [flagDownloadModal, setFlagDownloadModal] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagDownloadModal(false)
    }, [selectRef]);

    const [updatedSetting, setUpdatedSetting] = useState(false);
    useEffect(()=> {
        if(dataReady) {
            setUpdatedSetting(true);
        }
    },[activeFilter, activeSort])

    return(
        <div className="table-server">
            {name &&
                <div className={'table-server__name'}>
                    {name}
                    {!offAgg && tooltip && <Tooltip text={tooltip}/>}
                    {subDescription && <span>{subDescription}</span> }
                    {!offAgg && dataWrapper.length > 0 && aggChoice.length > 0 && Object.values(selectDataset).length > 0 &&
                        <div className={'table-server__agg'}>
                            <Select
                                selectActive={selectActive}
                                dataset={selectDataset}
                                onChangeActive={onChangeSelect}
                                color={'dark'}
                                widthAuto={true}
                                checkbox={true}
                                listActive={aggColIndexesDefault.map((item)=>String(item))}
                            />
                        </div>
                    }
                    {columnPreferences.length>0 && dataWrapper.length > 0 &&<div className={'table-server-download'} ref={selectRef}>
                        <button className={`table-server-download__title ${flagDownloadModal ? 'active':''}`} onClick={()=>setFlagDownloadModal(!flagDownloadModal)}>
                            <img src={downloadIcon} alt=""/>
                        </button>
                        {flagDownloadModal && <div className="table-server-download__popup">
                            <Btn
                                text={trans('Download in CSV', language)}
                                color={'blue'}
                                onClick={()=>{
                                    const sort:any[] = []
                                    Object.keys(activeSort).map((name)=> {
                                        if(activeSort[name] !== 'default') {
                                            sort.push({
                                                name: name,
                                                value: activeSort[name]
                                            });
                                        }
                                    })
                                    const aggregation:string[] = []
                                    aggColIndexesDefault.map((item)=> {
                                        if(columnPreferences[item])
                                            aggregation.push(columnPreferences[item].name);
                                    })
                                    const filter:any[] = [];
                                    Object.keys(activeFilter).map((name)=> {
                                        filter.push({
                                            name: name,
                                            condition: activeFilter[name].condition,
                                            value: activeFilter[name].value
                                        });
                                    })
                                    onDownload('csv', sort, filter, aggregation)
                                }}
                                isfullWidth={true}
                            />
                            {/*<Btn*/}
                            {/*    text={trans('Download in Excel', language)}*/}
                            {/*    color={'blue'}*/}
                            {/*    onClick={()=>{*/}
                            {/*        const sort:any[] = []*/}
                            {/*        Object.keys(activeSort).map((name)=> {*/}
                            {/*            if(activeSort[name] !== 'default') {*/}
                            {/*                sort.push({*/}
                            {/*                    name: name,*/}
                            {/*                    value: activeSort[name]*/}
                            {/*                });*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*        const aggregation:string[] = []*/}
                            {/*        aggColIndexesDefault.map((item)=> {*/}
                            {/*            if(columnPreferences[item])*/}
                            {/*                aggregation.push(columnPreferences[item].name);*/}
                            {/*        })*/}
                            {/*        const filter:any[] = [];*/}
                            {/*        Object.keys(activeFilter).map((name)=> {*/}
                            {/*            filter.push({*/}
                            {/*                name: name,*/}
                            {/*                condition: activeFilter[name].condition,*/}
                            {/*                value: activeFilter[name].value*/}
                            {/*            });*/}
                            {/*        })*/}
                            {/*        onDownload('xlsx', sort, filter, aggregation)*/}
                            {/*    }}*/}
                            {/*    isfullWidth={true}*/}
                            {/*/>*/}
                        </div>}
                    </div>}
                </div>
            }
            <div className="table-server__table" style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ? {overflowX:'auto'} : {}}>
                <div style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ?{minWidth: `${150*dataWrapper[0].filter((item:any)=>item !== '8|}$$~').length}px`}:{}}>
                    <table>
                        <thead>
                            <tr>
                                {data.length > 0 && columnPreferences.map((item:any, index:number)=> (
                                    item.type !== 'skip' && <TableServerHeadItem
                                        key={index}
                                        title={item.title}
                                        index={index}
                                        name={item.name}
                                        fieldType={item.type}
                                        fieldTypes={fieldTypes}
                                        activeFilter={activeFilter}
                                        setActiveFilter={setActiveFilter}
                                        onFilter={onChangeFilter}
                                        valueSearch={valueSearch}
                                        activeSort={activeSort}
                                        setActiveSort={setActiveSort}
                                        onChangeSorting={onChangeSort}
                                        data={data}
                                        dataWrapper={dataWrapper}
                                        setDataWrapper={setDataWrapper}
                                        offFilter={offFilter}
                                    />
                                ))}
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={'table-server__scroll'} style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ? {minWidth: `${150*dataWrapper[0].filter((item:any)=>item !== '8|}$$~').length}px`, minHeight: '200px'} : {}}>
                    <table>
                        <tbody>
                            {columnPreferences.length>0 && dataWrapper.length > 0 && dataWrapper.slice((currentPage-1)*limitChannelCustom, currentPage*limitChannelCustom).map((item:any, index:number)=> (
                              <tr key={index}>
                                {item.map((value:string, key:number)=>(
                                    value !== '8|}$$~' && typeof columnPreferences === 'object' && wrapperIndexes.length > 0 && columnPreferences[wrapperIndexes[key]] &&
                                    <td key={key} className={`table-server-row__item ${columnPreferences[wrapperIndexes[key]].type === 'date' ? 'date-value' : ''} ${columnPreferences[wrapperIndexes[key]] && columnPreferences[wrapperIndexes[key]].type === 'string' ? '' : ''}`}>
                                      <div className="table-server-row__text">
                                        {(columnPreferences[wrapperIndexes[key]].type) &&
                                          formattingValues(value, columnPreferences[wrapperIndexes[key]].type, currency)}
                                      </div>
                                      { columnPreferences[wrapperIndexes[key]].type === 'string' && value.toString().length > 18 &&
                                          <Alt text={value} active={true}/>
                                      }
                                    </td>
                                ))}
                              </tr>
                            ))
                            }
                        </tbody>
                    </table>
                    {(updatedSetting || noActual) &&
                        <div className='table-server-update'>
                            <div className="table-server-update__block">
                                <p>{trans('The data is not up to date', language)}</p>
                                <Btn
                                    text={trans('Update', language)}
                                    color={'light-blue'}
                                    onClick={()=>{
                                        setUpdatedSetting(false);
                                        const sort:any[] = []
                                        Object.keys(activeSort).map((name)=> {
                                            if(activeSort[name] !== 'default') {
                                                sort.push({
                                                    name: name,
                                                    value: activeSort[name]
                                                });
                                            }
                                        })
                                        const aggregation:string[] = []
                                        aggColIndexesDefault.map((item)=> {
                                            if(columnPreferences[item])
                                                aggregation.push(columnPreferences[item].name);
                                        })
                                        const filter:any[] = [];
                                        Object.keys(activeFilter).map((name)=> {
                                            filter.push({
                                                name: name,
                                                condition: activeFilter[name].condition,
                                                value: activeFilter[name].value
                                            });
                                        })
                                        onRepeat(sort, filter, aggregation,offset,limit);
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                { (aggUpdate && !dataReady) &&
                    <div className="table-server__loader">
                        <Loader inBlock={true} isNotTransparent={true}/>
                    </div>
                }
            </div>

            { (loader) &&
                <Loader inBlock={true} isNotTransparent={true}/>
            }
            {
                (dataWrapper.length === 0 || columnPreferences.length===0) && !aggUpdate ? (Object.keys(activeFilter).length === 0 ?
                <Empty title={trans('Nothing found', language)} btnCallback={onRepeat} /> :
                <Empty title={trans('Nothing found', language)} btnCallback={()=>{
                    setActiveFilter({})
                    onChangeFilter()
                    const aggregation:string[] = []
                    aggColIndexesDefault.map((item)=> {
                        if(columnPreferences[item])
                            aggregation.push(columnPreferences[item].name);
                    })
                    onRepeat({}, {}, aggregation,offset, limit);
                }} btnText={'Clear filters'} />) :
                <></>
            }


            {
              data.length > 0 && per_page_max > 1 && columnPreferences.length>0 &&
                <Pagination
                    noRowCount={true}
                    limitChannelCustom={limitChannelCustom}
                    setLimitChannelCustom={setLimitChannelCustom}
                    per_page_max={per_page_max}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    offset={offset}
                    limit={(limit/limitChannelCustom)}
                    onChangeOffset={(offsetRow:number, limitRow:number)=>{
                        const sort:any[] = []
                        Object.keys(activeSort).map((name)=> {
                            if(activeSort[name] !== 'default') {
                                sort.push({
                                    name: name,
                                    value: activeSort[name]
                                });
                            }
                        })
                        const aggregation:string[] = []
                        aggColIndexesDefault.map((item)=> {
                            if(columnPreferences[item])
                                aggregation.push(columnPreferences[item].name);
                        })
                        const filter:any[] = [];
                        Object.keys(activeFilter).map((name)=> {
                            filter.push({
                                name: name,
                                condition: activeFilter[name].condition,
                                value: activeFilter[name].value
                            });
                        })
                        onRepeat(sort, filter, aggregation, offsetRow, limitRow);
                    }}
                />
            }
        </div>
    )
}

export default TableServer;
