import {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";

import iconModel from '../../../../../assets/icons/model.svg'
import iconWindow from '../../../../../assets/icons/window.svg'

import Select from "../../../../../components/ui/Select";
import TimeFilter from "../../../../../components/ui/TimeFilter";
import {QueueServer, sendSdk} from "../../../../../utils";

import cx from 'classnames'
import './style.scss';
import iconPeriod from "../../../../../assets/icons/period.svg";
import ShortInfo from "../components/ShortInfo";
import DashboardsChart from "../components/Charts";
import {trans} from "../../../../../_locales";
import iconTarget from "../../../../../assets/icons/target-icon.svg";
import {getEvents} from "../../../../../actions/events";
import {eventsData} from "../../../../../redux/slices/events";
import DashboardsTable from "../components/Table";
import {onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {onLoadingSelectDate} from "../functions";
import {Groups, Models, Windows} from "../../../../../data";
import {
    dashboardsSettings,
    setSetting,
    setSettingEventTarget,
    setSettingModel, setSettingScale, setSettingWindow
} from "../../../../../redux/slices/dashboards";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";

const DashboardGeneral = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const settings = useAppSelector(dashboardsSettings);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');

    const [firstLoading, setFirstLoading] = useState(true);

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'dashboard_general'}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        const tempBreadcrumbs:any = [
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `/projects/${projectId}/reports/`,
              title: trans('Project reporting', language)
            },
            {
              href: `#`,
              title: trans('General indicators', language),
              dataset: {}
            }
        ]
        if(isViewEcom || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['ecommerce'] = {
                title: trans('E-Commerce Reports', language),
                href: `/projects/${projectId}/reports/ecommerce`
            }
        }
        if(isViewUserBehavior || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['user_behavior'] = {
                title: trans('User behavior analysis', language),
                href: `/projects/${projectId}/reports/user_behavior`
            }
        }
        if(projectId && (+projectId === 12484 || +projectId===12483)) {
            tempBreadcrumbs[2].dataset = {};
            tempBreadcrumbs[2].dataset['getintent'] = {
                title: 'GetIntent',
                href: `/projects/${projectId}/reports/getintent`
            }
        }
        dispatch(setBreadcrumbs(tempBreadcrumbs));
        dispatch(clearInstructions());
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboards'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));

        if(!(settings && settings.categorize)) {
            if (projectId == '777') {
                setCategorize('day');
            } else setCategorize('day');
        }
    }, [dispatch, projectId, permissions]);

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period,
                    model: settings.model ? settings.model : parsed.model,
                    window: settings.window ? settings.window : parsed.window,
                    categorize: settings.categorize ? settings.categorize : parsed.categorize,
                    event: settings.event ? settings.event : parsed.event
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [settings])

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [model, setModel] = useState('mtd');
    const [window, setWindow] = useState(30);
    const [categorize, setCategorize] = useState('day');
    const [selectDate, setSelectDate] = useState<string>(trans('Select the period', language));
    const [eventTarget, setEventTarget] = useState('');
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }
    const datasetModel = Models(language);
    const datasetWindow = Windows(language);
    const datasetCategorize = Groups(language);

    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {'all_target_events': trans('All event targets', language)})
    const [filterEventTarget, setFilterEventTarget] = useState<any>( {})
    const onChangeEventTarget = (key:any) => {
        setEventTarget(key)
        const temp:any = {};
        reduxEvents.map((item:any)=> {
            if(item.event_target_name === key) {
                temp.event_name= item.event_name;
                temp.event_type= item.event_type;
                temp.event_category= item.event_category;
                temp.event_target_name = item.event_target_name;
                temp.event_target_price = item.event_target_price;
            }
        })
        dispatch(setSettingEventTarget(temp))
        setFilterEventTarget(temp);
    }
    useEffect(() => {
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && parseSettings.event && parseSettings.event.event_target_name in temp) {
                    setEventTarget(parseSettings.event.event_target_name);
                    setFilterEventTarget(parseSettings.event)
                }
            }
        });
    }, [dispatch, language, projectId]);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    parseSettings && parseSettings.model ? setModel(parseSettings.model) : setModel(r.payload.attribution_model);
                    parseSettings && parseSettings.window ? setWindow(parseSettings.window) : setWindow(r.payload.attribution_window);
                    parseSettings && parseSettings.categorize ? setCategorize(parseSettings.categorize) : setCategorize('day');
                    if(parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else onLoadingSelectDate(r.payload.attribution_period, setPeriodStart, setPeriodEnd, setSelectDate);
                }
            })
        }

    }, [projectId, reduxUser.id])

    //-------End Filter--------

    const queue = new QueueServer();

    //Hide header when scroll
    const [lastScroll, setLastScroll] = useState(0);
    const defaultOffset = 200;
    const containHide = (elem:HTMLElement) => elem.classList.contains('hide');

    const handleScroll = (event:any) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.target;
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        if(body && header && content && scrollTop > lastScroll && !containHide(header) && scrollTop > defaultOffset && (scrollTop < scrollHeight - offsetHeight - 200)) {
            header.classList.add('hide');
            content.classList.add('stretch');
            body.classList.add('no-scroll');
        } else if(body && header && content && scrollTop < lastScroll && containHide(header)) {
            header.classList.remove('hide');
            content.classList.remove('stretch');
            body.classList.remove('no-scroll');
        }
        setLastScroll(scrollTop);
    }

  return (
    <div className={contentClassName} onScroll={()=>null}>
        <div className="project-filter">
            <div className="project-filter__date">
              <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
            </div>
            <div className="project-filter__model">
                <Select
                    selectActive={(datasetModel as any)[model]}
                    icon={iconModel}
                    dataset={datasetModel}
                    onChangeActive={(res:any) => onChangeGeneralSelect(res, (value:any) => {
                      setModel(value);
                      dispatch(setSettingModel(value))
                    })}
                />
            </div>
            <div className="project-filter__window">
              <Select selectActive={`${trans('Attribution window', language)}: ${window} ${trans('days', language)}`} icon={iconWindow} dataset={datasetWindow} onChangeActive={(res:any)=> {
                  onChangeGeneralSelect(res, (value:any) => {
                      const result = Number(value);
                      if(result === 15 || result === 30 || result === 60 || result === 90) {
                          setWindow(result);
                          dispatch(setSettingWindow(result))
                      }
                  });
              }} />
            </div>
            <div className="project-filter__window">
              <Select
                  selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                  icon={iconPeriod}
                  dataset={datasetCategorize}
                  onChangeActive={(res:any)=> onChangeGeneralSelect(res, (value:any) => {
                      setCategorize(value);
                      dispatch(setSettingScale(value))
                  })} />
            </div>
            <div className="dashboard-page-filter__select">
              <Select selectActive={(datasetEventTarget as any)[eventTarget] ? (datasetEventTarget as any)[eventTarget] : trans('All event targets', language)} icon={iconTarget} dataset={datasetEventTarget} onChangeActive={(r:any)=> onChangeGeneralSelect(r, onChangeEventTarget)} />
            </div>
        </div>
        <div className="project-summary">
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_uniq_user'}
                    titleDefault={trans('main_index_uniq_user', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_cr_user_to_lead'}
                    titleDefault={trans('main_index_cr_user_to_lead', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={window}
                    targetEvents={filterEventTarget}
                    queue={queue}
                />
            </div>
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_lead'}
                    titleDefault={trans('main_index_lead', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={window}
                    targetEvents={filterEventTarget}
                    queue={queue}
                />
            </div>
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_cost'}
                    titleDefault={trans('main_index_cost', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_cpa'}
                    titleDefault={trans('main_index_cpa', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model} window={window}
                    targetEvents={filterEventTarget}
                    queue={queue}
                />
            </div>
            <div className="project-summary__item">
                <ShortInfo
                    name={'main_index_media_reach'}
                    titleDefault={trans('main_index_media_reach', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                    side={'right'}
                />
            </div>
        </div>

        <div className="project-block">
            <div className="project-block__item">
                <div className="project__diagram">
                    <DashboardsChart
                        name={'main_chart_lead_cpa'}
                        titleDefault={trans('main_chart_lead_cpa', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarLinear'}
                        model={model}
                        window={window}
                        categorize={categorize}
                        targetEvents={filterEventTarget}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="project-block__item">
                <div className="project__diagram">
                    <DashboardsChart
                        name={'main_chart_cost'}
                        titleDefault={trans('main_chart_cost', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarStack'}
                        model={model}
                        window={window}
                        categorize={categorize}
                        labelIndex={0}
                        nameIndex={1}
                        dataIndexes={[2]}
                        queue={queue}
                    />
                </div>
            </div>
        </div>
        <div className="project-block size-l">
            <div className="project-block__item">
                <div className="project__diagram">
                    <DashboardsChart
                        name={'main_chart_uniq_user'}
                        titleDefault={trans('main_chart_uniq_user', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'LineFill'}
                        model={model}
                        window={window}
                        categorize={categorize}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="project-block__item">
                <div className="project__diagram">
                    <DashboardsChart
                        name={'main_chart_cr_user_to_lead'}
                        titleDefault={trans('main_chart_cr_user_to_lead', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Line'}
                        model={model}
                        window={window}
                        categorize={categorize}
                        targetEvents={filterEventTarget}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="project-block__item">
                <div className="project__diagram">
                    <DashboardsChart
                        name={'main_chart_lead'}
                        titleDefault={trans('main_chart_lead', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'LineFill'}
                        model={model}
                        window={window}
                        categorize={categorize}
                        targetEvents={filterEventTarget}
                        queue={queue}
                    />
                </div>
            </div>
        </div>

        <div className="project-table">
            <div className="project__table">
                <DashboardsTable
                    name={'main_table_full_stat'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={window}
                    categorize={categorize}
                    targetEvents={filterEventTarget}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>


        <div className="project-table">
            <div className="project__table">
                <DashboardsTable
                    name={'main_table_model_attr_compare'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    window={window}
                    targetEvents={filterEventTarget}
                    categorize={categorize}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
        <div className="project-table">
            <div className="project__table">
                <DashboardsTable
                    name={'main_table_lead_ptd'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={window}
                    categorize={categorize}
                    targetEvents={filterEventTarget}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
    </div>
  )
}

export default DashboardGeneral;