import React, {FC, useState, useEffect} from 'react';

interface ICodeFormatter {
  code: string,
  className?: string
}

const CodeFormatter:FC<ICodeFormatter> = ({ code, className }) => {
  const [formattedCode, setFormattedCode] = useState('');

  const formatCode = (code: string) => {
    const trimmedCode = code.trim();

    const indentedCode = trimmedCode.replace(/^/gm, '  ');

    setFormattedCode(indentedCode);
  };

  useEffect(() => {
    formatCode(code);
  }, [code]);

  return (
    <pre className={className}>
      <code>
        {formattedCode}
      </code>
    </pre>
  );
};

export default CodeFormatter;