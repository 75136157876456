import {IFieldItem} from "../interfaces.d";
import Select from "../../ui/Select";
import darkClose from "../../../assets/icons/icon-close.svg";
import React from "react";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ReactComponent as IconDrag} from "../../../assets/icons/drag.svg";


const FieldItem = ({
    item,
    dataset,
    datasetBase,
    defaultActive,
    onRemove,
    onChange
}:IFieldItem) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: item.id});
    const style = {
        transform: CSS.Translate.toString({
            x: transform ? transform.x : 0,
            y: transform ? transform.y : 0,
            scaleX: 1,
            scaleY: 1
        }),
        transition
    };
    return(
        <div
            className="reports-create__field reports-create__field_auto"
            ref={setNodeRef}
            style={transform ? style : {}}
        >
            <div className="reports-create-box__field">
                <button className={'reports-create-box__drag'} {...attributes}{...listeners}>
                    <IconDrag />
                </button>
                 <Select
                    selectActive={datasetBase[item.name] ? datasetBase[item.name] : defaultActive}
                    dataset={dataset}
                    onChangeActive={(r:any)=>onChange(r, item.id)}
                    color={'dark'}
                    widthAuto={true}
                    search={true}
                />
                <button className='reports-create-box__remove' onClick={()=>onRemove(item.id)}>
                    <img src={darkClose} alt=""/>
                </button>
            </div>
        </div>
    )
}

export default FieldItem;