export const fields = {
    'interaction_dt': 'Период',
    'campaign_nm': 'Название кампании',
    'placement_nm': 'Название креатива',
    "source_nm": "Медиа источник",
    'ssp': 'SSP',
    'domain': 'Домен',
    'device_type': 'Тип девайса'
}
export const fieldsConditions = {
    // 'interaction_dt': {
    //     'contain': 'Содержит',
    //     'not_contain': 'Не содержит',
    //     'equal': 'Равно',
    //     'not_equal': 'Не равно'
    // },
    'campaign_id': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'placement_id': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "source_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'ssp': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'domain': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'device_type': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    }
}
export const fieldsConditionValue = {
    // 'interaction_dt': '',
    'campaign_id': '',
    'placement_id': '',
    'source_nm': '',
    'ssp': '',
    'domain': '',
    'device_type': {
        'desktop': 'Десктоп',
        'mobile': 'Телефон',
        'table': 'Планшет',
        'app': 'Приложение',
        'tv': 'ТВ',
        'other': 'Другое'
    }
}

export const fieldsConditionValueTypes = {
    'campaign_id': 'string',
    'placement_id': 'string',
    'ssp': 'string',
    'domain': 'string',
    'device_type': 'object'
}

export const metrics = {
    'unique_devices': 'Охват',
    'impressions': 'Показы',
    'givt': "GIVT",
    // 'fraud_impressions': 'Сомнительные показы',
    // 'part_of_fraud_impressions': 'Доля сомнительных показов',
    'clicks': 'Клики',
    'visible_impressions': 'Видимые показы',
    'part_of_visible_impressions': 'Доля видимых показов',
    'part_of_screen': 'Часть от экрана',
    'part_of_visible_screen': 'Видимая часть от экрана',
    'visible_part': 'Видимая часть',
    'creative_weight': 'Ширина креатива',
    'creative_height': 'Высота креатива',
    'video_start': 'Старт видео',
    'video_start_on_mute': 'Старт видео с отключенным звуком',
    'video_q25': '25% видео',
    'video_q50': '50% видео',
    'video_q75': '75% видео',
    'video_complete': 'Досмотр видео',
    'video_close': 'Закрыто видео',
    'video_skip': 'Пропуск видео'
}

export const metricsConditions = {
    'unique_devices': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'clicks': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_visible_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_visible_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_part': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_weight': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_height': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start_on_mute': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q25': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q50': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q75': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_complete': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_close': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_skip': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValue = {
    'unique_devices': '',
    'impressions': '',
    'givt': '',
    'fraud_impressions': '',
    'part_of_fraud_impressions': '',
    'clicks': '',
    'visible_impressions': '',
    'part_of_visible_impressions': '',
    'part_of_screen': '',
    'part_of_visible_screen': '',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_close': '',
    'video_skip': ''
}

export const metricsConditionValueTypes = {
    'unique_devices': '',
    'impressions': '',
    'givt': '',
    'fraud_impressions': '',
    'part_of_fraud_impressions': 'percent',
    'clicks': '',
    'visible_impressions': '',
    'part_of_visible_impressions': 'percent',
    'part_of_screen': '',
    'part_of_visible_screen': 'percent',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_close': '',
    'video_skip': ''
}

export const sortConditions = {
    'asc': 'Ascending',
    'desc': 'Descending',
}


export const fieldsSite = {
    'period_dt': 'Период',
    'campaign_nm': 'Название кампании',
    'placement_nm': 'Название креатива'
}
export const fieldsConditionsSite = {
    // 'interaction_dt': {
    //     'contain': 'Содержит',
    //     'not_contain': 'Не содержит',
    //     'equal': 'Равно',
    //     'not_equal': 'Не равно'
    // },
    'campaign_nm': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'placement_nm': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    }
}
export const fieldsConditionValueSite = {
    // 'interaction_dt': '',
    'campaign_nm': '',
    'placement_nm': ''
}

export const fieldsConditionValueTypesSite = {
    'campaign_nm': 'string',
    'placement_nm': 'string'
}

export const metricsSite = {
    spent_metrics: "Расходы",
    plan_spent_metrics: "Расходы план",
    part_plan_spent: "Расходы выполнение",
    impression_metrics: "Показы",
    plan_impression_metrics: "Показы план",
    part_plan_impression: "Показы выполнение",
    cpm: "CPM",
    plan_cpm: "CPM план",
    part_plan_cpm: "CPM выполнение",
    givt_metrics: "GIVT",
    // fraud_impressions_metrics: "SIVT",
    // fraud_share: "Доля сомнительных показов (GIVT + SIVT)",
    reach_metrics: "Охват",
    plan_reach_metrics: "Охват план",
    part_plan_reach: "Охват выполнение",
    // cpr: "Цена за охват 1К юзеров",
    // plan_cpr: "Цена за охват 1К юзеров план",
    // part_plan_cpr: "Цена за охват 1К выполнение",
    imp_on_uniq: "Частота",
    ctr: "CTR",
    click_metrics: "Клики",
    plan_click_metrics: "Клики план",
    part_plan_click: "Клики выполнение",
    cpc: "CPC",
    plan_cpc: "CPC план",
    part_plan_cpc: "CPC выполнение",
    lead_metrics: "Целевые действия",
    cpa: "Стоимость целевого действия",

    consumed_lead_metrics: "Значимые целевые действия",
    cpa_consumed: "Стоимость значимого целевого действия",

    ecom_add_to_cart_metrics: "Добавлений в корзину",
    ecom_purchases_metrics: "Покупки",
    cr_cart_to_purchase: "Конверсия из добавления в корзину в покупку",

    ecom_add_to_cart_consumed_metrics: "Значимые добавления в корзину",
    ecom_purchases_consumed_metrics: "Значимые покупки",
    ecom_quantity_metrics: "Количество товаров",
    ecom_amount_metrics: "Сумма покупок",

    ecom_quantity_consumed_metrics: "Значимо куплено товаров",
    ecom_amount_consumed_metrics: "Значимая сумма покупок",
    ecom_avg_bill: "Средний чек",
    ecom_drr: "Доля рекламных расходов"
}

export const metricsConditionsSite = {
    'spent_metrics': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_spent_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_spent: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    impression_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_impression_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_impression: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    givt_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    fraud_impressions_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    fraud_share: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    reach_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_reach_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_reach: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    imp_on_uniq: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ctr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    click_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_click_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_click: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    lead_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpa: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_add_to_cart_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_purchases_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cr_cart_to_purchase: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_quantity_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_amount_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_avg_bill: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_drr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValueSite = {
    spent_metrics: "",
    plan_spent_metrics: "",
    part_plan_spent: "",
    impression_metrics: "",
    plan_impression_metrics: "",
    part_plan_impression: "",
    cpm: "",
    plan_cpm: "",
    part_plan_cpm: "",
    givt_metrics: "",
    fraud_impressions_metrics: "",
    fraud_share: "",
    reach_metrics: "",
    plan_reach_metrics: "",
    part_plan_reach: "",
    cpr: "",
    plan_cpr: "",
    part_plan_cpr: "",
    imp_on_uniq: "",
    ctr: "",
    click_metrics: "",
    plan_click_metrics: "",
    part_plan_click: "",
    cpc: "",
    plan_cpc: "",
    part_plan_cpc: "",
    lead_metrics: "",
    cpa: "",
    ecom_add_to_cart_metrics: "",
    ecom_purchases_metrics: "",
    cr_cart_to_purchase: "",
    ecom_quantity_metrics: "",
    ecom_amount_metrics: "",
    ecom_avg_bill: "",
    ecom_drr: ""
}

export const metricsConditionValueTypesSite = {
    spent_metrics: "",
    plan_spent_metrics: "",
    part_plan_spent: "percent",
    impression_metrics: "",
    plan_impression_metrics: "",
    part_plan_impression: "percent",
    cpm: "",
    plan_cpm: "",
    part_plan_cpm: "percent",
    givt_metrics: "",
    fraud_impressions_metrics: "",
    fraud_share: "",
    reach_metrics: "",
    plan_reach_metrics: "",
    part_plan_reach: "percent",
    cpr: "",
    plan_cpr: "",
    part_plan_cpr: "percent",
    imp_on_uniq: "",
    ctr: "",
    click_metrics: "",
    plan_click_metrics: "",
    part_plan_click: "percent",
    cpc: "",
    plan_cpc: "",
    part_plan_cpc: "percent",
    lead_metrics: "",
    cpa: "",
    ecom_add_to_cart_metrics: "",
    ecom_purchases_metrics: "",
    cr_cart_to_purchase: "",
    ecom_quantity_metrics: "",
    ecom_amount_metrics: "",
    ecom_avg_bill: "",
    ecom_drr: ""
}


export const fieldsEcom = {
    "trg_event_dt": "Дата целевого действия",
    "trg_platform": "Платформа целевого действия",
    "trg_url_domain": "Домен целевого взаимодействия",
    "event_dt": "Дата взаимодействия",
    "interaction_type": "Тип взаимодействия",
    "utm_source": "Utm Source",
    "utm_medium": "Utm Medium",
    "utm_campaign": "Utm Campaign",
    "source_nm": "Медиа источник",
    "campaign_nm": "Медиа Кампания",
    "placement_nm": "Медиа Креатив",
    "url_domain": "Домен взаимодействия",
    "ssp": "SSP",
    "platform": "Платформа взаимодейтсивя",

    "ecom_item_id": "Id товара",
    "ecom_item_name": "Наименование товара",
    "ecom_item_category_1": "Категория товара 1",
    "ecom_item_category_2": "Категория товара 2",
    "ecom_item_brand": "Бренд Товара",
}
export const fieldsEcomItems = {
    "ecom_item_id": "Id товара",
    "ecom_item_name": "Наименование товара",
    "ecom_item_category_1": "Категория товара 1",
    "ecom_item_category_2": "Категория товара 2",
    "ecom_item_brand": "Бренд Товара"
}
export const fieldsConditionsEcom = {
    "trg_event_dt": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "trg_platform": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "trg_url_domain": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "event_dt": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "interaction_type": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_source": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_medium": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_campaign": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "source_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "campaign_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "placement_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "url_domain": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "ssp": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "platform": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    //
    // "ecom_item_id": "",
    // "ecom_item_name": "",
    // "ecom_item_category_1": "",
    // "ecom_item_category_2": "",
    // "ecom_item_brand": ""
}
export const fieldsConditionValueEcom = {
    "trg_event_dt": "",
    "trg_platform": "",
    "trg_url_domain": "",
    "event_dt": "",
    "interaction_type": "",
    "utm_source": "",
    "utm_medium": "",
    "utm_campaign": "",
    "source_nm": "",
    "campaign_nm": "",
    "placement_nm": "",
    "url_domain": "",
    "ssp": "",
    "platform": "",

    "ecom_item_id": "",
    "ecom_item_name": "",
    "ecom_item_category_1": "",
    "ecom_item_category_2": "",
    "ecom_item_brand": "",
}

export const fieldsConditionValueTypesEcom = {
    "trg_event_dt": "",
    "trg_platform": "",
    "trg_url_domain": "",
    "event_dt": "",
    "interaction_type": "",
    "utm_source": "",
    "utm_medium": "",
    "utm_campaign": "",
    "source_nm": "",
    "campaign_nm": "",
    "placement_nm": "",
    "url_domain": "",
    "ssp": "",
    "platform": "",

    "ecom_item_id": "",
    "ecom_item_name": "",
    "ecom_item_category_1": "",
    "ecom_item_category_2": "",
    "ecom_item_brand": "",
}

export const metricsEcom = {
    "users": "Уникальных пользователей",
    "add_to_cart_item_quantity": "Добавлено в корзину шт.",
    "purchase_item_quantity": "Продано шт.",
    "purchase_item_amount": "Выручка",
    "AOV": "Средний чек"
}

export const metricsConditionsEcom = {
    "users": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "add_to_cart_item_quantity": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "purchase_item_quantity": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "purchase_item_amount": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "AOV": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValueEcom = {
    "users": "",
    "add_to_cart_item_quantity": "",
    "purchase_item_quantity": "",
    "purchase_item_amount": "",
    "AOV": ""
}

export const metricsConditionValueTypesEcom = {
    "users": "",
    "add_to_cart_item_quantity": "",
    "purchase_item_quantity": "",
    "purchase_item_amount": "",
    "AOV": ""
}