import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {getDashboardsFilterValues} from "../../actions/dashboards";

interface DashboardsState {
  filter: Array<any>
  filterValues: any
  loaded: boolean
  isLoading: boolean
  error: string | null
  settings: {
    period?: {
      start: string,
      end: string
    },
    model?: 'fl' | 'fli' | 'ffi'| 'ftd'| 'ftdm'| 'ml'| 'mli'| 'mfi'| 'mtd',
    window?: 15 | 30 | 60 | 90,
    categorize?: 'day' | 'week' | 'month' | 'quarter' | 'year',
    event?: {
        event_name: string,
        event_type: string,
        event_category: string,
        event_target_name: string,
        event_target_price: number,
    }
  }
}

const initialState: DashboardsState = {
  filter: [],
  filterValues:{},
  settings:{},
  loaded: false,
  isLoading: false,
  error: null
}

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearFilter: (state) => {
      state.filter = [];
    },
    setSetting: (state, action:PayloadAction<DashboardsState['settings']>) => {
      state.settings = action.payload;
    },
    setSettingPeriod: (state, action:PayloadAction<DashboardsState['settings']['period']>) => {
      state.settings.period = action.payload;
    },
    setSettingModel: (state, action:PayloadAction<DashboardsState['settings']['model']>) => {
      state.settings.model = action.payload;
    },
    setSettingWindow: (state, action:PayloadAction<DashboardsState['settings']['window']>) => {
      state.settings.window = action.payload;
    },
    setSettingScale: (state, action:PayloadAction<DashboardsState['settings']['categorize']>) => {
      state.settings.categorize = action.payload;
    },
    setSettingEventTarget: (state, action:PayloadAction<DashboardsState['settings']['event']>) => {
      state.settings.event = action.payload;
    },
    clearSetting: (state) => {
      state.settings = {};
    },
    setDashboardsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardsFilterValues.fulfilled, (state, action: PayloadAction<any>) => {
      state.filterValues = action.payload ? action.payload : {};
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(getDashboardsFilterValues.pending, (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(getDashboardsFilterValues.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
  }
})

export const {
  removeError,
  clearFilter,
  setFilter,
  setDashboardsLoaded,
  setSetting,
  setSettingPeriod,
  setSettingModel,
  setSettingWindow,
  setSettingScale,
  setSettingEventTarget,
  clearSetting
} = dashboardsSlice.actions

export const errorValue = createSelector(
  (state: RootState) => state.dashboards,
  (dashboards) => dashboards.error
)

export const dashboardsLoading = createSelector(
  (state: RootState) => state.dashboards,
  (dashboards) => dashboards.isLoading
)

export const dashboardsFilter = createSelector(
  (state: RootState) => state.dashboards,
  (dashboards) => dashboards.filter
)

export const dashboardsFilterValues = createSelector(
  (state: RootState) => state.dashboards,
  (dashboards) => dashboards.filterValues
)

export const dashboardsSettings = createSelector(
  (state: RootState) => state.dashboards,
  (dashboards) => dashboards.settings
)

export default dashboardsSlice.reducer
