import {FC, useRef} from "react";
import './style.scss';
import {useOnClickOutside} from "../../../../helpers";

interface ISelectOptions {
    onChange:any
    dataWrapper:any
    activeFlag?:boolean
    activeKey?:string
    setFlagSort?:any
}

const SelectOptions:FC<ISelectOptions> = ({
    onChange,
    dataWrapper,
    activeFlag=false,
    activeKey,
    setFlagSort
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagSort(false)
    }, [selectRef]);
    return(
        <div className={`select-options ${activeFlag ? 'active' : ''}`} ref={selectRef}>
            {Object.keys(dataWrapper).map((key:string, index:number)=>(
                <button key={index} className={`select-options__btn ${activeKey === key ? 'active':''}`} onClick={()=>onChange(key)}>
                    {(dataWrapper as any)[`${key}`]}
                </button>
            ))}
        </div>
    )
}

export default SelectOptions;