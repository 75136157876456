import {FC, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/hooks";
import useOnScreen from "../../../../../../hooks/useOnScreen";
import { ReactComponent as FullScreenIcon } from '../../../../../../assets/icons/fullscreen.svg';
import { ReactComponent as DotsIcon } from '../../../../../../assets/icons/icon-dots-black.svg';
import { ReactComponent as FullScreenExitIcon } from "../../../../../../assets/icons/fullscreen-exit.svg";
import { ReactComponent as DownloadIcon} from '../../../../../../assets/icons/download.svg';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line, Bar, Chart, Pie} from "../../../../../../ui/ChartJs";

import {getDashboards} from "../../../../../../actions/project";
import {projectGeneral} from "../../../../../../redux/slices/project";
import {openPopup} from "../../../../../../redux/slices/popup";
import {PopupType} from "../../../../../../data";

import Loader from "../../../../../../components/Loader";
import Empty from "../../../../../../components/Empty";

import {onHandlerChart} from "../../functions";
import './style.scss';
import {trans} from "../../../../../../_locales";
import {currentUserLang} from "../../../../../../redux/slices/user";
import {QueueServer} from "../../../../../../utils";
import Btn from "../../../../../../ui/Btn";
import {closeModal, ModalName, openModal} from "../../../../../../redux/slices/modals";
import {DefaultObject} from "../../../../../../interfaces/common.d";
import {downloadChartReport} from "../../../../../../components/TablePagination/functions";
import {useOnClickOutside} from "../../../../../../helpers";

export interface IDashboardsChart {
    name:string,
    titleDefault?:string,
    projectId:number,
    periodStart:string,
    periodEnd:string,
    model?:string,
    categorize?:string,
    window?:number,
    filter?:any,
    targetEvents?:any,
    type:'Pie' | 'Bar' | 'Line' | 'BarStack' | 'BarLinear' | 'LineFill' | 'LineMulti' | 'LineMultiFill' | 'BarStackLinear',
    labelIndex?:number
    nameIndex?:number
    dataIndexes?:Array<number>
    positionLabels?:'top'|'right'|'left'|'bottom'
    queue?: QueueServer
    weight?: number
    isOpenFullScreen?:boolean,
    isFirstLoading?:boolean,
    defaultResponse?:DefaultObject<any>,
    isShort?:boolean
}

const DashboardsChart:FC<IDashboardsChart> = ({
    name,
    titleDefault='',
    projectId,
    periodStart,
    periodEnd,
    model,
    window,
    categorize,
    filter,
    targetEvents,
    type,
    labelIndex=0,
    nameIndex,
    dataIndexes=[1],
    positionLabels='top',
    queue,
    weight = 6,
    isOpenFullScreen=true,
    defaultResponse,
    isFirstLoading=false,
    isShort=false
}) => {
    const dispatch = useAppDispatch();
    const generalProjectInfo = useAppSelector(projectGeneral);
    const language = useAppSelector(currentUserLang);
    const currency= generalProjectInfo && generalProjectInfo.currency || 'USD';

    ChartJS.register(
        ArcElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Filler,
        BarElement
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Tooltip.positioners.bottom = function(items:any) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pos = Tooltip.positioners.average(items);

        // Happens when nothing is found
        if (pos === false) {
            return false;
        }

        return {
            x: pos.x,
            y: pos.y,
            xAlign: 'center',
            yAlign: 'bottom',
        };
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Tooltip.positioners.custom = function(elements:any , position:any) {
        console.log(elements);
        if (!elements.length) {
            return false;
        }
        let offset = 0;
        //adjust the offset left or right depending on the event position
        if (elements[0].element.width / 2 > position.x) {
            offset = 20;
        } else {
            offset = -20;
        }
        return {
            x: position.x + offset,
            y: position.y
        }
    }

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [no, setNo] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataTable, setDataTable] = useState<any>();
    const [options, setOptions] = useState<any>()
    const [plugins, setPlugins] = useState<Array<any>>([])
    const ref = useRef<HTMLDivElement | null>(null)
    const legend = useRef<HTMLDivElement | null>(null)
    const isVisible = useOnScreen(ref);
    const [firstLoading, setFirstLoading] = useState<boolean>(isFirstLoading);
    const [currentResponse, setCurrentResponse] = useState<DefaultObject<any>>(defaultResponse?defaultResponse:{});

    const on = (response:any) => {
        if (response && response.data) {
            setDataTable(response);
            const setting:any = {}
            if(type === 'Pie') setting.pie = true;
            if(type === 'Bar') setting.bar = true;
            if(type === 'Line') setting.linear = true;
            if(type === 'LineFill') {
                setting.linear = true;
                setting.fill = true;
            }
            if(type === 'LineMulti') {
                setting.name_index = nameIndex;
                setting.linear_multi = true;
            }
            if(type === 'LineMultiFill') {
                setting.name_index = nameIndex;
                setting.linear_multi = true;
                setting.fill = true;
            }
            if(type === 'BarLinear') setting.bar_linear = true;
            if(type === 'BarStack') {
                setting.bar_stack = true;
                setting.name_index = nameIndex;
            }
            if(type === 'BarStackLinear') {
                setting.bar_stack_linear = true;
                setting.name_index = nameIndex;
            }

            const getOrCreateLegendList = (chart:any, legendContainer:HTMLElement) => {
              let listContainer = legendContainer && legendContainer.querySelector('ul');

              if (!listContainer) {
                listContainer = document.createElement('ul');
                listContainer.style.display = 'flex';
                listContainer.style.flexDirection = 'row';
                listContainer.style.margin = String(0);
                listContainer.style.padding = String(0);

                legendContainer && legendContainer.appendChild(listContainer);
              }

              return listContainer;
            };

            const htmlLegendPlugin = {
                id: 'htmlLegend',
                afterUpdate(chart: any, args: any, options: any) {
                    const ul = getOrCreateLegendList(chart, options.containerRef.current);

                    // Remove old legend items
                    while (ul.firstChild) {
                        ul.firstChild.remove();
                    }

                    // Reuse the built-in legendItems generator
                    const items = chart.options.plugins.legend.labels.generateLabels(chart);

                    items.forEach((item: any) => {
                        if(item.text) { // check for not empty title
                            const li = document.createElement('li');
                            li.style.alignItems = 'center';
                            li.style.cursor = 'pointer';
                            li.style.display = 'flex';
                            li.style.flexDirection = 'row';
                            li.style.marginLeft = '10px';
                            li.style.marginBottom = '5px';

                            li.onclick = () => {
                                const {type} = chart.config;
                                if (type === 'pie' || type === 'doughnut') {
                                    // Pie and doughnut charts only have a single dataset and visibility is per item
                                    chart.toggleDataVisibility(item.index);
                                } else {
                                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                                }
                                chart.update();
                            };

                            // Color box
                            const boxSpan = document.createElement('span');
                            if (boxSpan) {
                                boxSpan.style.background = item.fillStyle;
                                boxSpan.style.borderColor = item.strokeStyle;
                                boxSpan.style.borderWidth = item.lineWidth + 'px';
                                boxSpan.style.display = 'inline-block';
                                boxSpan.style.flexShrink = String(0);
                                boxSpan.style.height = '2px';
                                boxSpan.style.marginRight = '10px';
                                boxSpan.style.width = '20px';
                            }

                            // Text
                            const textContainer = document.createElement('p');
                            textContainer.style.color = item.fontColor;
                            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                            const text = document.createTextNode(item.text);
                            textContainer.appendChild(text);

                            li.appendChild(boxSpan);
                            li.appendChild(textContainer);
                            ul.appendChild(li);
                        }
                    });
                }
            };
            setPlugins([htmlLegendPlugin])
            onHandlerChart(response, setShowLoader,setData, setOptions, currency, setting,labelIndex,dataIndexes, positionLabels, legend)
        }
    }

    const serverCb = (r:any) => {
        if (r.payload && r.payload[name]) {
            if (typeof r.payload[name].error === 'string') {
                dispatch(openPopup({type: PopupType.ERROR, description: r.payload[name].error}));
                setNo(true);
            } else {
                try {
                    setCurrentResponse(r);
                    on(r.payload[name]);
                    setNo(false);
                } catch (e) {
                    console.log('Error', e);
                }
            }
        } else {
            setNo(true);
        }
        setShowLoader(false);
    }

    useEffect(()=> {
        if(defaultResponse) {
            serverCb(defaultResponse);
        } else setFirstLoading(false);
    }, [])
    useEffect(() => {
        if (projectId && periodStart && periodEnd) {
            setFirstLoading(false);
        }
    },[projectId, periodStart, periodEnd, model, window, categorize, filter, targetEvents, dispatch])
    useEffect(()=> {
        if(projectId && periodStart && periodEnd && isVisible && !firstLoading) {
            setShowLoader(true);
            const getData = () => {
                return dispatch(getDashboards({
                    projectId: Number(projectId),
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    names: [
                        name
                    ],
                    model: model,
                    window: window,
                    filter: filter,
                    categorize: categorize,
                    targetEvents: targetEvents,
                })).then((r) => {
                    serverCb(r);
                })
            }
            if(queue) {
                queue.enqueue(() => {
                    return getData();
                }, weight);
            } else {
                getData();
            }

            setFirstLoading(true);
        }
    },[projectId, periodStart, periodEnd, model, window, categorize, filter, targetEvents, isVisible,firstLoading])

    const [flagOptionsModal, setFlagOptionsModal] = useState(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagOptionsModal(false)
    }, [optionsRef]);

    return(
        <div ref={ref} className='charts'>
            {data && data.datasets && data.datasets.length > 0 && data.datasets[0] && data.datasets[0].data && data.datasets[0].data.length > 0 && <div ref={legend} className={`charts__head ${isShort ? 'short' : ''}`}></div>}
          { showLoader ?
            <Loader inBlock={true} /> : no ?
            <Empty title={`${titleDefault ? titleDefault : 'Undefined'} ${trans('missing', language)}`} btnCallback={()=>setFirstLoading(false)} /> :
            (data && data.datasets && data.datasets.length > 0 && data.datasets[0] && data.datasets[0].data && data.datasets[0].data.length > 0) ?
            ((type === 'Pie' && <Pie data={data} options={options} plugins={plugins}/>) ||
            ((type === 'Bar' || type === 'BarStack' || type === 'BarStackLinear') && <Bar data={data} options={options} plugins={plugins}/>) ||
            (type === 'BarLinear' && <Chart type='bar' data={data} options={options} plugins={plugins}/>) ||
            ((type === 'Line' || type === 'LineFill' || type === 'LineMulti' || 'LineMultiFill') && <Line data={data} options={options} plugins={plugins}/>) )
            :
            <Empty title={`${titleDefault ? titleDefault : 'Undefined'} ${trans('missing', language)}`} />
          }
          {
            <div className='charts-full' ref={optionsRef}>
                <Btn
                    className='charts-full__btn'
                    color={'dark'}
                    onClick={()=> {
                          setFlagOptionsModal(!flagOptionsModal);
                    }}
                    isIconBtn={true}
                    icon={{
                        Component: DotsIcon,
                        width: 15,
                        height: 15
                    }}
                />
                {flagOptionsModal && <div className="charts-popup">
                    {isOpenFullScreen && <Btn
                        className='charts-popup__btn'
                        color={'dark'}
                        isIconBtn={true}
                        icon={{
                            Component: FullScreenIcon,
                            width: 16,
                            height: 16
                        }}
                        onClick={()=> {
                              dispatch(openModal({
                                    name: ModalName.DASHBOARD_FULLSCREEN,
                                    data: {
                                        title: titleDefault ? titleDefault : data.item_title,
                                        response: currentResponse,
                                        type: 'chart',
                                        projectId: projectId,
                                        data: {
                                            name,
                                            titleDefault,
                                            projectId,
                                            periodStart,
                                            periodEnd,
                                            model,
                                            window,
                                            categorize,
                                            filter,
                                            targetEvents,
                                            type,
                                            labelIndex,
                                            nameIndex,
                                            dataIndexes,
                                            positionLabels,
                                            queue,
                                            weight,
                                            isOpenFullScreen: false,
                                            defaultResponse: currentResponse,
                                            fullscreen: true,
                                            isFirstLoading: true
                                        }
                                    }
                              }))
                        }}
                    />}
                    {
                        !isOpenFullScreen &&
                            <Btn
                                className='charts-popup__btn'
                                color={'dark'}
                                isIconBtn={true}
                                icon={{
                                    Component: FullScreenExitIcon,
                                    width: 16,
                                    height: 16
                                }}
                                onClick={()=> {
                                      dispatch(closeModal(ModalName.DASHBOARD_FULLSCREEN))
                                }}
                            />
                    }
                    {dataTable && <div>
                        <Btn
                            className='charts-popup__btn'
                            color={'dark'}
                            isIconBtn={true}
                            icon={{
                                Component: DownloadIcon,
                                width: 16,
                                height: 16
                            }}
                            onClick={()=>downloadChartReport({
                                type: 'xlsx',
                                name,
                                dataWrapper: dataTable.data.dataset,
                                titles: dataTable.data.column ? dataTable.data.column.map((item:any)=>item.name) : [],
                                aggFunctions: dataTable.data.column ? dataTable.data.column.map((item:any)=>item.agg_function) : [],
                                columnPreferences: dataTable.data.column ? dataTable.data.column : [],
                                fieldTypes: dataTable.data.column ? dataTable.data.column.map((item:any)=>item.type) : [],
                                currency
                            })}
                        />
                    </div>}
                </div>}
            </div>
          }
        </div>
    )
}

export default DashboardsChart;