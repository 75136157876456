import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";

import {trans} from "../../../../../_locales";
import {getProjectAttributions, getProjectGeneral} from "../../../../../actions/project";
import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import iconModel from "../../../../../assets/icons/model.svg";
import iconWindow from "../../../../../assets/icons/window.svg";
import iconPeriod from "../../../../../assets/icons/period.svg";
import iconTarget from "../../../../../assets/icons/target-icon.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-white.svg'
import IconFilterWhite from "../../../../../assets/icons/icon-filter-white.svg";
import IconFilter from "../../../../../assets/icons/icon-filter-blue.svg";
import {Btn} from "../../../../../ui";
import DashboardsTable from "../../Dashboards/components/Table";
import cx from "classnames";
import {
    getFromObject,
    getInterval,
    parseCustomFilter,
    parseFilter,
    parseSort,
    removeObjectKeys,
    sendSdk
} from "../../../../../utils";
import './style.scss';
import {getReport} from "../../../../../actions/reports";
import Loader from "../../../../../components/Loader";
import {onLoadingSelectDate} from "../../Dashboards/functions";
import {eventsData} from "../../../../../redux/slices/events";
import {getEvents} from "../../../../../actions/events";
import {Groups, Models, Windows} from "../../../../../data";
import {onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {clearFilter, setFilter as setDispatchFilter, setSettingEventTarget} from "../../../../../redux/slices/dashboards";
import {ICustomReport} from "../../../../../components/ReportsUpdate/interfaces.d";
import {getPages} from "../../../../../components/SideMenu/Role";


const ReportsView = () => {
    const dispatch = useAppDispatch();
    const { projectId, reportId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const reduxEvents = useAppSelector(eventsData);
    const language = useAppSelector(currentUserLang);
    const navigate = useNavigate();
    const role:string = reduxUser && reduxUser.roles ? reduxUser.roles[`${projectId}`] : "";
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    // const dashboard = 'custom_table_domain';
    // const dashboard = 'custom_table_full_stat';
    const [dashboard, setDashboard] = useState<string>('custom_table_main');
    useEffect(()=> {
        if (projectId && reduxUserId && reportId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'custom_reports_view',
                    'custom_report_id': reportId
                }
            })
        }
        const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('custom_reports');
        const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('custom_reports');
        const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('custom_reports');
        const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('custom_reports');

        if(permissions && !isView) {
            navigate(`/projects/${projectId}/custom-reports/`)
        }
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
    }, [projectId, reduxUserId, reportId, permissions])

    useEffect(() => {
        dispatch(clearInstructions());
        dispatch(setBreadcrumbs([
            {
                href: '/projects/',
                title: 'Проект',
            },
            {
                href: `/projects/${projectId}/custom-reports/`,
                title: trans('Custom reports', language)
            },
            {
                href: `/projects/${projectId}/custom-reports/view/`,
                title: trans('Preview', language)
            }
        ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && parseSettings.event && parseSettings.event.event_target_name in temp) {
                    setEventTarget(parseSettings.event.event_target_name);
                    setFilterEventTarget(parseSettings.event)
                }
            }
        });
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));
    }, [dispatch, language, projectId, role]);

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow', 'custom-report-view');

    const [firstLoading, setFirstLoading] = useState(true);

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [categorize, setCategorize] = useState('day');
    const [selectDate, setSelectDate] = useState<string>(trans('Select the period', language));
    const [serverNoActual, setServerNoActual] = useState(false);
    const [model, setModel] = useState('mtd');
    const [window, setWindow] = useState(30);
    const [eventTarget, setEventTarget] = useState('');

    const datasetCategorize = Groups(language);
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }

    const datasetModel = Models(language);
    const datasetWindow = Windows(language);

    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {'all_target_events': trans('All event targets', language)})
    const [filterEventTarget, setFilterEventTarget] = useState<any>( {})
    const onChangeEventTarget = (response:object) => {
        for (const key in response) {
            setEventTarget(key)
        }
    }

    useEffect(()=>{
        if(eventTarget && Array.isArray(reduxEvents) && reduxEvents.length > 0) {
            const temp:any = {};
            reduxEvents.map((item:any)=> {
                if(item.event_target_name === eventTarget) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                }
            })
            dispatch(setSettingEventTarget(temp))
            setFilterEventTarget(temp);
        }
    }, [reduxEvents, eventTarget])


    const [data, setData] = useState<any>();
    const [dataReady, setDataReady] = useState<boolean>(false);
    const [defaultAgg, setDefaultAgg] = useState<Array<string>>([]);
    const [defaultFilter, setDefaultFilter] = useState<Array<any>>([]);
    const [defaultSort, setDefaultSort] = useState<Array<any>>([]);
    const [defaultMetric, setDefaultMetric] = useState<Array<any>>([]);
    const [defaultEventTarget, setDefaultEventTarget] = useState<any>({});

    const [customFilters, setCustomFilters] = useState<Array<any>>([]);
    const [customTrgFilters, setCustomTrgFilters] = useState<Array<any>>([]);
    const [ecomFilters, setEcomFilters] = useState<Array<any>>([]);
    const [extendFilters, setExtendFilters] = useState<Array<any>>([]);
    const [interactionType, setInteractionType] = useState<ICustomReport["interaction_type"]>(['Impression'])
    const [trgPeriodStart, setTrgPeriodStart] = useState<string>('');
    const [trgPeriodEnd, setTrgPeriodEnd] = useState<string>('');

    useEffect(() => {
        if(firstLoading && reduxEvents && !dataReady) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
              if(r.payload) {
                onLoadingSelectDate(r.payload.attribution_period, setPeriodStart, setPeriodEnd, setSelectDate);
              }
            })
            dispatch(getReport({ projectId: Number(projectId), reportId: Number(reportId)})).then((r)=> {
                if (r.payload) {
                    if(r.payload.fields && typeof r.payload.fields === 'string')
                        setDefaultAgg(r.payload.fields.split(','));
                    if(r.payload.filter_fields)
                        setCustomFilters(parseFilter(r.payload.filter_fields));
                    if (r.payload.filter_ecom) {
                        setEcomFilters(parseCustomFilter(r.payload.filter_ecom));
                    }
                    if (r.payload.filter) {
                        setFilter(parseCustomFilter(r.payload.filter));
                        setDefaultFilter(parseCustomFilter(r.payload.filter));
                        dispatch(setDispatchFilter(parseCustomFilter(r.payload.filter)));
                        setFilterActive(true);
                    }
                    if (r.payload.filter_trg) {
                        setCustomTrgFilters(parseFilter(r.payload.filter_trg));
                    }
                    if(r.payload.interaction_type) {
                        const temp:any = r.payload.interaction_type;
                        const list = temp.split('&')
                        setInteractionType(list);
                    }
                    setData(r.payload);
                    if(r.payload.type && r.payload.type === 'general_custom_table') {
                        setDashboard('custom_table_general')
                    }
                    if(r.payload.type && r.payload.type === 'ecommerce') {
                        setDashboard('custom_table_ecommerce_items')
                    }
                    if(r.payload.sort)
                        setDefaultSort(parseSort(r.payload.sort));
                    if(r.payload.metrics && typeof r.payload.metrics === 'string') {
                        setDefaultMetric(r.payload.metrics.split(','))
                    }
                    if(r.payload.event_target) {
                        setEventTarget(r.payload.event_target);
                    }

                    setWindow(r.payload.attribution_window);
                    setModel(r.payload.attribution_model);
                    setCategorize(r.payload.grouping);
                    const interval = r.payload.period !== 'choice' ? getInterval(new Date(), r.payload.period) : {interval_from: r.payload.interval_from, interval_to: r.payload.interval_to};
                    if(interval) {
                        onChangeSelectDate({
                            start: interval.interval_from,
                            end: interval.interval_to
                        })
                    }
                    if(r.payload.trg_period || (r.payload.trg_interval_from && r.payload.trg_interval_to)) {
                       const trgInterval = r.payload.trg_period !== 'choice' && r.payload.trg_period !== 'none' ? getInterval(new Date(), r.payload.trg_period) : {interval_from: r.payload.trg_interval_from, interval_to: r.payload.trg_interval_to};
                        if(trgInterval) {
                            setTrgPeriodStart(trgInterval.interval_from);
                            setTrgPeriodEnd(trgInterval.interval_to);
                        }
                    }

                }
            }).then(()=>{
                setTimeout(()=> {
                    setDataReady(true)
                }, 500);
            });
        }
    }, [projectId, reduxUser.id, reportId, reduxEvents])

    // ------Filter--------
    const [filter, setFilter] = useState<Array<any>>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:any) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'view_custom_report',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    return(
        <div className={contentClassName}>
            <div className="dashboard-page-filter static">
                <div className="dashboard-page-filter__date">
                    <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
                </div>
                {data && (data.type === 'general_custom_table' || data.type === 'ecommerce') && <div className="dashboard-page-filter__select">
                    <Select selectActive={(datasetModel as any)[model]} icon={iconModel} dataset={datasetModel}
                            onChangeActive={(res: object) => onChangeGeneralSelect(res, setModel)}/>
                </div>}
                {data && (data.type === 'general_custom_table' || data.type === 'ecommerce') && <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={`${trans('Attribution window', language)}: ${window} ${trans('days', language)}`}
                        icon={iconWindow} dataset={datasetWindow}
                        onChangeActive={(res: object) => onChangeGeneralSelect(res, (value: any) => setWindow(+value))}/>
                </div>}
                <div className="dashboard-page-filter__select">
                    <Select selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                            icon={iconPeriod} dataset={datasetCategorize}
                            onChangeActive={(res: object) => onChangeGeneralSelect(res, setCategorize)}/>
                </div>
                {data && (data.type === 'general_custom_table') && <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={(datasetEventTarget as any)[eventTarget] ? (datasetEventTarget as any)[eventTarget] : trans('All event targets', language)}
                        icon={iconTarget} dataset={datasetEventTarget} onChangeActive={onChangeEventTarget}/>
                </div>}
                {<div className="dashboard-page-filter__auto">
                    <button className={`dashboard-page-filter__btn ${filterActive ? 'active' : ''}`} onClick={() => {
                        projectId && dispatch(openModal({
                            name: ModalName.FILTER,
                            data: {
                                onSubmit: onSubmitFilter,
                                projectId: +projectId
                            }
                        }))
                    }}>
                        <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                        {trans('Filter', language)}
                    </button>
                    {filterActive && <Btn
                        className={'dashboard-page-filter__reset'}
                        isIconBtn={true}
                        size='sm'
                        color='transparent'
                        onClick={onResetFilter}
                        icon={{
                            Component: CloseIcon,
                            width: 16,
                            height: 16
                        }}
                    />}
                </div>}
            </div>
            <div className="dashboard-page-table">
                <div className="dashboard-page__table">
                    {dataReady && data ? <DashboardsTable
                            name={dashboard}
                            type={data.type}
                            defaultName={data.name}
                            projectId={Number(projectId)}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            categorize={categorize}
                            window={window}
                            model={model}
                            filter={filter}
                            targetEvents={filterEventTarget}
                            serverGroup={true}
                            noActual={serverNoActual}
                            setActual={setServerNoActual}
                            offAgg={true}
                            defaultAgg={defaultAgg}
                            defaultFilter={defaultFilter}
                            customFilters={customFilters}
                            defaultSort={defaultSort}
                            metricInclude={true}
                            defaultMetric={defaultMetric}
                            defaultRowsOnPage={30}
                            isDefaultRequest={false}
                            customTrgFilters={customTrgFilters}
                            ecomFilters={ecomFilters}
                            interactionType={interactionType}
                            trgPeriodStart={trgPeriodStart}
                            trgPeriodEnd={trgPeriodEnd}
                    /> :
                    <Loader inBlock={true} isNotTransparent={true}/>}
                </div>
            </div>
        </div>
    )

}

export default ReportsView;