import {FC, useEffect, useRef, useState} from "react";
import {IDataSelect} from "./interfaces.d";

import {openPopup} from "../../../redux/slices/popup";
import {PopupType} from "../../../data";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useOnClickOutside} from "../../../helpers";
import {checkVisible, filterObject} from "../../../utils";

import cx from "classnames";
import {Toggle} from "../../../ui";
import Input from "../../../ui/Input";
import {Link} from "react-router-dom";
import {trans} from "../../../_locales";
import {currentUserLang} from "../../../redux/slices/user";
import Tooltip from "../../Tooltip";
import Btn from "../../../ui/Btn";

import './style.scss';

import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-close.svg'
import {onChangeGeneralCheckboxItem} from "./functions";

const Select:FC<IDataSelect> = ({
    selectActive,
    icon,
    onlyIcon=false,
    label,
    dataset,
    onChangeActive,
    onInput,
    input,
    inputConfig,
    inputIcon,
    className='',
    color,
    colorText,
    name,
    widthAuto=false,
    checkbox=false,
    isLink=false,
    listActive=[],
    search=false,
    isDisabled=false,
    tooltip,
    isInvalid=false,
    errorLabel,
    onError,
    required,
    position,
    maxChecked,
    minChecked,
    response,
    resetBtn=false,
    placeholder='',
    resetCheckbox=false
}) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(currentUserLang);
  const [showModelAttributions, setShowModelAttributions] = useState<boolean>(false);

  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(() => setShowModelAttributions(false), [selectRef]);

  const [orientationBoxTop, setOrientationBoxTop] = useState<boolean>(false);

  const [dataWrapper, setDataWrapper] = useState<any>(dataset);

  useEffect(()=> {
      setDataWrapper(dataset);
  },[dataset])

  const changeSelect = (e:any, key:any) => {
    Object.values(e.target.parentNode.children).map((item:any)=> (
       item.className = ''
    ))
    e.target.className = 'active';
    setShowModelAttributions(false);
    if(name && response === 'name_key') {
        const temp:any = {};
        temp[`${name}`] = `${key}`;
        onChangeActive(temp);
        return;
    }
    const temp:any = {};
    temp[`${key}`] = (dataset as any)[`${key}`];
    onChangeActive(temp);
  }

  const [dataCheckbox, setDataCheckbox] = useState<any>({});
  useEffect(()=> {
    const result = {};
    listActive.map(item=> (result as any)[item]={'checked':true, 'name':(dataset as any)[item]});
    setDataCheckbox(result);
  }, [])
  const changeCheckbox = (checked:boolean, key:any) => {
      // if(maxChecked && Object.keys(filterObject(dataCheckbox, (val:any, key:string) => val.checked)).length >= maxChecked) {
      //     if(!(key in dataCheckbox) || dataCheckbox[key].checked === false) {
      //         dispatch(openPopup({ type: PopupType.WARNING, description: `${trans('You can select a maximum of', language)} ${maxChecked}` }));
      //         return;
      //     }
      // }
      // const copy = selectActive && selectActive !== trans('Value', language) ? JSON.parse(JSON.stringify(dataCheckbox)): {};
      // copy[key]={
      //     checked: checked,
      //     name: (dataset as any)[`${key}`],
      //     key: key
      // };
      // if(minChecked && Object.keys(filterObject(copy, (val:any, key:string) => val.checked)).length < minChecked) {
      //     if((key in dataCheckbox) && dataCheckbox[key].checked === true && !copy[key].checked) {
      //         dispatch(openPopup({ type: PopupType.WARNING, description: `${trans('You can select a minimum of', language)} ${minChecked}` }));
      //         return;
      //     }
      // }
      const {result} = onChangeGeneralCheckboxItem({
          checked: checked,
          key,
          dataCheckbox,
          selectActive,
          allNames: dataset,
          dispatch,
          minChecked,
          maxChecked,
          language,
          placeholder: 'Value'
      })
      if(result) {
          setDataCheckbox(result);
          onChangeActive(result);
      }
  }

  const onResetCheckbox = () => {
      setDataCheckbox({});
      onChangeActive({});
  }

  const onClickSelect = (e:any) => {
      if(e.target.closest('.select__input') === null && e.target.closest('.select__reset') === null) {
          const block = selectRef.current;
          setOrientationBoxTop(checkVisible(block,200));
          setShowModelAttributions(!showModelAttributions);
      }
  }

  const [nDaysValue, setNDaysValue] = useState(inputConfig&&typeof inputConfig.default === 'number' ? `${inputConfig.default}` : '');
    useEffect(() => {
        if(inputConfig && inputConfig.default) {
            setNDaysValue(`${inputConfig.default}`)
        }
    }, [inputConfig]);
  const [error, setError] = useState(false);

  useEffect(()=> {
      const delay = setTimeout(()=> {
          setShowModelAttributions(false);
          if(input) {
              const limit = inputConfig&&inputConfig.limit ? inputConfig.limit : 365;
              const day = +nDaysValue;
              if(isNaN(Number(nDaysValue)) || day > limit || day < 1 || nDaysValue === '') {
                  setError(true);
                  dispatch(openPopup({ type: PopupType.ERROR, description: `${trans('Enter a number from 1 to',language)} ${limit}` }));
                  onError && onError(`${trans('Enter a number from 1 to',language)} ${limit}`)
                  return;
              }
              setError(false);
              onInput(+day);
          }
      }, 500);
      return () => clearTimeout(delay);
  }, [nDaysValue])

  const selectClassName = cx(
    'select',
    className,
    {
        'select--width-auto': widthAuto,
        'select--alice-blue': color === 'light-blue',
        'select--dark': color === 'dark',
        'select--transparent': color === 'transparent',
        'select--dark-text': colorText === 'dark',
        'select--disabled': isDisabled,
        'select--invalid': isInvalid,
        'select--label': label,
        'select--right': position === 'right'
    }
  );

  const [searchValue, setSearchValue] = useState('');
  const onSearchValue = (response:any) => {
      setSearchValue(response.search);
      const copyDataset = JSON.parse(JSON.stringify(dataset));
      setDataWrapper(filterObject(copyDataset, (val:any, key:any)=> new RegExp(response.search.toLowerCase()).test(String(val).toLowerCase())));
  }

  const onResetValue = () => {
      if (name && response === 'name_key') {
          const temp: any = {};
          temp[`${name}`] = '';
          onChangeActive(temp);
          return;
      }
      const temp: any = {};
      // temp[`${key}`] = (dataset as any)[`${key}`];
      onChangeActive(temp);
  }

  return (
    <div className={selectClassName} ref={selectRef}>
        <div>
            {label && <span className='select__label'>{label} {tooltip && <Tooltip text={tooltip}/>} {required && <span className='red'>*</span>}</span>}
            <button name={name ? name : 'select-label'} type='button' className={`select__block ${showModelAttributions && 'active'} ${input && 'input'}`} onClick={(e)=>onClickSelect(e)}>
                {input && inputIcon ? <img src={inputIcon} alt=""/> : icon && <img src={icon} alt=""/>}
                {
                    input ?
                    <div className='select__input'>
                        {inputConfig?.before}
                        <input
                            className={`select__input_field ${error ? 'error' : ''}`}
                            type="text"
                            value={nDaysValue}
                            onChange={(e)=>{setNDaysValue(e.target.value)}}
                        />
                        {inputConfig?.after}
                    </div>
                        :
                    !onlyIcon && <span>{name && response === 'name_key' ? ((dataset as any)[`${selectActive}`] ? (dataset as any)[`${selectActive}`] : placeholder) : selectActive}</span>
                }
                {selectActive && resetBtn && !isDisabled && <Btn
                  className={'select__reset'}
                  isIconBtn={true}
                  size='sm'
                  color='transparent'
                  onClick={onResetValue}
                  icon={{
                    Component: CloseIcon,
                    width: 16,
                    height: 16
                  }}
              />}
            </button>
            {errorLabel && <span className="custom-select__error">{errorLabel}</span>}
        </div>
        <div className={`select__content ${showModelAttributions ? 'active' : ''} ${orientationBoxTop ? '' : 'top'}`}>
            {search &&
                <div className={'select__search'}>
                    <Input
                        className='select__search_input'
                        name={'search'}
                        isSearch={true}
                        value={searchValue}
                        onChange={onSearchValue}
                    />
                    {resetCheckbox &&
                        <div className='select__reset_icon'>
                            <Btn
                                color={'dark'}
                                isIconBtn={true}
                                icon={{
                                    Component: CloseIcon,
                                    width: 16,
                                    height: 16
                                }}
                                onClick={()=> {
                                    onResetCheckbox();
                                }}
                            />
                        </div>

                    }
                </div>
            }
            {Object.keys(dataWrapper).length > 0 ? Object.keys(dataWrapper).map((key:any, index:number)=>(
                key ?
                    (!checkbox ? isLink ?
                <Link key={index} to={dataWrapper[key].href} className='select__link' onClick={(e)=>{
                    changeSelect(e,key)
                }}>
                    {dataWrapper[key].title + ' '}
                </Link> :
                <button key={index} type='button' onClick={(e) => changeSelect(e,key)}>
                    {(dataWrapper as any)[`${key}`]}
                </button> :
                <div key={index} className={'select__checkbox'}>
                    <Toggle
                        name='row-choice'
                        borderMod={true}
                        isChecked={listActive.includes(key)}
                        onChange={(evt)=>changeCheckbox(evt.currentTarget.checked, key)}
                        label={(dataWrapper as any)[`${key}`]}
                    />
                </div>) : ''
                )) : <span style={{textAlign: 'center'}}>Не найдено</span>
            }
        </div>
    </div>
  )
}

export default Select;